/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import { ConfigService } from '@shared/services/config.service';
import { environment } from '@environments/environment';
import {
  filter,
  flatMap,
  map,
  switchMap,
  take
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileService } from '../profile/profile.service';
import { SearchAdContentData } from '@shared/services/ads/search-ad-content-data.model';
import {
  SearchAdsItemContent,
  SearchAdsItemContentOption,
  SearchAdsItemResponse,
  SearchAdsResponse
} from '@shared/services/ads/search-ads-items-response.interface';
import { TokensService } from '@shared/services/tokens.service';
import { UserModel } from '@app/core/auth/services/user.model';

export interface SearchAdsParams {
  type: string;
  specials?: string;
  excludeBrands?: string;
  excludeCategories?: string;
  sites?: string;
  areaCountries?: string;
  keywordTypes?: string;
  keywords?: string;
  excludeKeywordTypes?: string;
  excludeKeywords?: string;
  siteTag?: string;
}

export interface SearchQueryParams {
  areaCountries: string;
  type: string;
  excludeGuids: string;
  limit: number;
  specials: string;
  excludeBrands: string;
  excludeCategories: string;
  sites: string;
  keywordTypes: string;
  keywords: string;
  excludeKeywordTypes: string;
  excludeKeywords: string;
  siteTag: string;
}

export const CONTENT_SOURCE_OFF_INHERIT = 'off_inherit';

@Injectable({
  providedIn: 'root'
})
export class SearchAdsService {
  private profileData: Observable<UserModel>;

  constructor(
    private profileService: ProfileService,
    private configService: ConfigService,
    private tokensService: TokensService,
    private http: HttpClient
  ) {
    this.getProfileData();
  }

  getKeywordTypesValue(queryParams: { key: string; value: string }[], withOr = true): string {
    const orSuffix = '^or';
    return queryParams
      .filter(i => i.value.length > 0)
      .map(i => withOr
        ? (i.value.toLocaleLowerCase().includes(orSuffix) ? `${i.key}${orSuffix}` : i.key)
        : i.key
      )
      .join(',');
  }

  getKeywordsValue(queryParams: { key: string; value: string }[]): string {
    return queryParams
      .filter(i => i.value.length > 0)
      .map(i => this.parseKeywordValue(i.value))
      .join(',');
  }

  parseKeywordValue(value: string): string {
    return value ? value
      .trim()
      .replace(/\^or/ig, '')
      .replace(/(\s+)/g, ',')
      : '';
  }

  isEnoughParamsForSearchAds(params: SearchAdsParams): boolean {
    return !!(
      (params.keywordTypes && params.keywordTypes.length > 0)
      &&
      (params.keywords && params.keywords.length > 0)
    );
  }

  getProfileData(): void {
    this.profileData = new Observable(subscription => {
      this.profileService.profileData$.pipe(
        filter(profileData => Object.keys(profileData).length > 0),
        take(1)
      ).subscribe(profileData => {
        subscription.next(profileData);
        subscription.complete();
      });
    });
  }

  overrideContentDataByAdContent(item: SearchAdsItemResponse): SearchAdsItemResponse {
    // populate offer Data by ad's content
    const items = this.getContentBySubdomain(item, this.configService.getOption('subdomain'));
    if (items && items.length > 0) {
      items.forEach(contentItem => item.content_data.setOption(contentItem.key, contentItem.value));

      if (item.content_data.is_allowed_destination_url === undefined) {
        item.content_data.is_allowed_destination_url = true;
      }
    }
    return item;
  }

  overrideDynamicAdContent(options: SearchAdsItemContentOption[]): SearchAdsItemContentOption[] {
    const offDestinationUrl = options.find(option => option.key === 'off_destination_url');
    const offDestinationUrlNewTab = options.find(option => option.key === 'off_destination_url_new_tab');

    if (offDestinationUrl) {
      const isDefaultNewTab = !offDestinationUrl.value.toLowerCase().includes('%ebghost%');

      if (offDestinationUrlNewTab === undefined) {
        options.push({
          key: 'off_destination_url_new_tab',
          value: isDefaultNewTab.toString()
        });
      } else if (offDestinationUrlNewTab.value === undefined) {
        offDestinationUrlNewTab.value = isDefaultNewTab.toString();
      }
    }

    return options;
  }

  async populateDynamicSearchAdsContent(ads: SearchAdsItemResponse[]) {
    const dynamicUrls = [];

    ads.forEach(ad => {
      const contentOptions = this.getContentBySubdomain(ad, this.configService.getOption('subdomain'));
      const { isEbg, urls } = this.getAdUrls(contentOptions);

      dynamicUrls.push(...urls);

      ad.isEbg = isEbg;
      if (ad.content && ad.content.length > 0) {
        ad.content.forEach(i => this.overrideDynamicAdContent(i.options));
      }
    });

    const populatedUrls = await this.tokensService.getPopulatedDynamicURLs(dynamicUrls.filter(Boolean));

    ads.forEach(ad => {
      const [destinationUrl, offDestinationUrl, buttonUrl, linkUrl] = dynamicUrls.splice(0, 4);
      const adContentOptions = (ad.content && ad.content.length > 0) ? ad.content.reduce((acc, i) => {

        if (i.sub_domain === 'default.wag3' || i.sub_domain === 'default.bp') {
          i.options[0].key = i.sub_domain;
        }

        acc.push(...i.options);
        return acc;
      }, [] as SearchAdsItemContentOption[]) : [];

      if (destinationUrl) {
        this.setContentOptions(adContentOptions, 'destination_url', populatedUrls.shift());
      }

      if (offDestinationUrl) {
        this.setContentOptions(adContentOptions, 'off_destination_url', populatedUrls.shift());
      }

      if (buttonUrl) {
        this.setContentOptions(adContentOptions, 'button_url', populatedUrls.shift());
      }

      if (linkUrl) {
        this.setContentOptions(adContentOptions, 'link_url', populatedUrls.shift());
      }

    });
  }

  getAdUrls(options: SearchAdsItemContentOption[]) {
    let isEbg = false;
    const urls = [];

    [
      'destination_url',
      'off_destination_url',
      'button_url',
      'link_url'
    ].forEach(key => {
      const url = this.getContentOption(options, key, '');

      if (this.tokensService.isTokenPresent(url, 'ebghost')) {
        isEbg = true;
      }

      urls.push(url);
    });

    return { urls, isEbg };
  }

  getContentOption(options: SearchAdsItemContentOption[], key: string, defaultValue: ''): string {
    const option = options.find(i => i.key === key);
    return option ? option.value : defaultValue;
  }

  setContentOptions(options: SearchAdsItemContentOption[], key: string, value: string) {
    options
      .filter(option => option.key === key)
      .forEach(option => option.value = value);
  }

  getOptionsBySubdomain(content: SearchAdsItemContent[], subdomain: string): SearchAdsItemContentOption[] {
    return ((content || []).find(i => i.sub_domain === subdomain) || { options: [] }).options;
  }

  getContentBySubdomain(item: Partial<SearchAdsItemResponse>, subdomain: string): { key: string; value: string }[] {
    // find all options that are related to this domain
    const subdomainOptions = this.getOptionsBySubdomain(item.content, subdomain);
    const subdomainOptionsKeys = subdomainOptions.map(i => i.key);

    // get all default options except subdomain related
    const defaultType = window.location.href.includes('workingadvantage.com') ? 'default.wag3' : 'default.bp';
    const platformOptions = this.getOptionsBySubdomain(item.content, defaultType)
      .filter(i => !subdomainOptionsKeys.includes(i.key));
    const platformOptionsKeys = platformOptions.map(i => i.key);
    const defaultOptions = this.getOptionsBySubdomain(item.content, 'default')
      .filter(i => !subdomainOptionsKeys.includes(i.key) && !platformOptionsKeys.includes(i.key));

    return [...subdomainOptions, ...platformOptions, ...defaultOptions];
  }

  getAds(adsFilter: SearchAdsParams, limit: number, excludeGuids = ''): Observable<SearchAdsResponse> {
    return this.profileData.pipe(
      switchMap(profileData => this.getAdsWithProfile(adsFilter, limit, (profileData.countryLast || 'US'), excludeGuids))
    );
  }

  private getAdsWithProfile(adsFilter: SearchAdsParams, limit: number, countryLast: string, excludeGuids = ''): Observable<SearchAdsResponse> {

    const queryParams = {} as SearchQueryParams;
    const isPaylogixAllowed = this.configService.getIsPaylogixAllowed();
    const areaCountries = adsFilter.hasOwnProperty('areaCountries') ? adsFilter.areaCountries : countryLast;
    queryParams['areaCountries'] = areaCountries;
    if (adsFilter.type) {
      queryParams['type'] = adsFilter.type;
    }
    if (adsFilter.siteTag) {
      queryParams['siteTag'] = adsFilter.siteTag;
    }
    if (excludeGuids) {
      queryParams['excludeGuids'] = excludeGuids;
    }

    queryParams['limit'] = limit;

    queryParams['isPaylogixAllowed'] = isPaylogixAllowed;

    ['specials',
      'excludeBrands',
      'excludeCategories',
      'sites',
      'keywordTypes',
      'keywords',
      'excludeKeywordTypes',
      'excludeKeywords'
    ].forEach(param => {
      if (adsFilter[param]) {
        queryParams[param] = adsFilter[param];
      }
    });
    const requestObs = this.getSearchRequest(queryParams);

    return requestObs
      .pipe(
        flatMap(async i => {
          await this.populateDynamicSearchAdsContent(i.items);
          return i;
        }),
        map(i => {
          i.items = i.items.map(j => {
            j.content_data = new SearchAdContentData({});
            return j;
          });
          return i;
        })
      );
  }

  private getSearchRequest(queryParams: SearchQueryParams): Observable<SearchAdsResponse> {
    if (this.configService.isUsePostForSearch()) {
      return this.http.post<SearchAdsResponse>(`${environment.apiUrl}/decisions/search`, queryParams);
    } else {
      const queryString = Object.keys(queryParams).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(queryParams[k])).join('&');
      return this.http.get<SearchAdsResponse>(`${environment.apiUrl}/decisions/search?${queryString}`);
    }
  }
}
