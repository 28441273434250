/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { cdnHostTypes, ConfigService } from './config.service';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from 'g3-common-ui';

@Injectable({
  providedIn: 'root'
})
export class ThemeStylesService {

  themeStyles: string;
  private themeColors = '';

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    @Inject(WINDOW) private window: WINDOW
  ) { }

  async getStyles(): Promise<any> {
    try {

      if (!(environment as any).e2e) {
        const subdomain = this.configService.getOption<string>('subdomain', this.window.location.host.split('.')[0]);
        const url = this.configService.getCdn(cdnHostTypes.apiHost) || environment.apiUrl;

        const [theme, colors] = await Promise.all([
          this.http.get(`${url}/api/${subdomain}/marketplace-styles.css`, { responseType: 'text' }).toPromise(),
          this.http.get(`${url}/api/${subdomain}/colors.css?scope=:root,app-logged-in,ngb-modal-window`, { responseType: 'text' }).toPromise()
        ]);

        this.themeStyles = theme;
        this.themeColors = colors;
      } else {
        this.themeStyles = '';
      }

      return this.themeStyles;

    } catch (err) {
      console.error(err);
      return null;
    }
  }

  setCustomStyles(): void {
    const customStyle = this.themeStyles;
    const colorTheme = this.themeColors;
    const document = this.window.document;

    const head = document.head || document.getElementsByTagName('head')[0];
    const styleTag = document.createElement('style');

    styleTag.appendChild(document.createTextNode(customStyle));
    const styleElements = head.getElementsByTagName('style');

    // place marketplace styles before other styles
    if (styleElements.length > 1) {
      head.insertBefore(styleTag, styleElements[0]);
    } else {
      head.appendChild(styleTag);
    }

    const themeStyleTag = document.createElement('style');
    themeStyleTag.appendChild(document.createTextNode(colorTheme));
    head.appendChild(themeStyleTag);
  }
}
