import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

/** A router wrapper, adding extra functions. */
@Injectable()
export class RouterExtService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(
    private router: Router
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.currentUrl !== event.url) {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
        }
      }
    });
  }

  public getPreviousUrl(): string {
    return this.previousUrl && this.previousUrl !== this.currentUrl ? this.previousUrl : '/';
  }
}
