/* eslint-disable typescriptESlintPlugin/explicit-function-return-type */
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { WINDOW } from 'g3-common-ui';
import { PillsService } from '@app/shared/services/pills.service';
import { Badge } from '@shared/interfaces/badge.interface';
import { OfferBadgeData } from '@shared/interfaces/offer-badge-data.interface';
import { OfferBadgeService } from '@shared/services/offer-badge.service';
import { OfferBadgePropertiesModel } from '@shared/models/offer-badge-properties.model';
import { ResizeEventsService } from '@shared/services/resize-events.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-offer-badge',
  templateUrl: './offer-badge.component.html',
  styleUrls: ['./offer-badge.component.less']
})
export class OfferBadgeComponent implements OnChanges, OnDestroy, OnInit {

  @Input() badgeIndex = '';
  @Input() offerData: OfferBadgeData;
  @Input() offerClass = '';
  @Input() withRightCorners = false;
  @Input() isPaylogixOffer = false;

  @Output() hasBadges = new EventEmitter<boolean>();

  badges = [] as Badge[];
  private lastBadgesValue: boolean;
  private destroyStream$ = new Subject<void>();

  constructor(
    @Inject(WINDOW) private window: WINDOW,
    private offerBadgeService: OfferBadgeService,
    private resizeEventsService: ResizeEventsService,
    private pillsService: PillsService
  ) { }

  ngOnInit(): void {
    this.resizeEventsService.badgeResize$.pipe(
      takeUntil(this.destroyStream$)
    ).subscribe(() => {
      this.updateBadges();
    });
  }

  ngOnChanges(): void {
    this.updateBadges();
  }

  ngOnDestroy(): void {
    this.destroyStream$.next();
    this.destroyStream$.complete();
  }

  updateBadges(): void {
    const offerProperties = new OfferBadgePropertiesModel({
      offerData: this.offerData,
      screenWidth: this.window.innerWidth,
      offerClass: this.offerClass,
      isPaylogixOffer: this.isPaylogixOffer
    });

    this.badges = this.pillsService.getPills(
      this.offerBadgeService.getBadgesForOffer(offerProperties)
    );

    const hasBadges = this.badges && !!this.badges?.length;
    if (this.lastBadgesValue !== hasBadges) {
      this.lastBadgesValue = hasBadges;
      this.hasBadges.emit(hasBadges);
    }

  }
}
