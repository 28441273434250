/* eslint-disable max-len */
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Inject, Injectable } from '@angular/core';
import { WINDOW, PlatformService, PlatformCountry, KnownUserTypes } from 'g3-common-ui';
import { DestinationUrlRequestParamsInterface } from '@shared/services/destination-url-request-params.interface';
import { OfferModel } from '@shared/models/offer.model';
import { SearchOfferForAdsInterface } from '@offers/services/search-offer-item.interface';
import { SearchResultsTodayDealModel } from '../models/search-results-today-deal.model';
import { ProfileService } from './profile/profile.service';
import { PermissionService } from '@app/core/auth/services/permission.service';
import { KnownUserService } from '@shared/services/known-user.service';
import { AuthService } from '@core/auth/services/auth.service';
import { ConfigService } from './config.service';
import { SearchResultsAdsModel } from '@shared/models/search-results-ads.model';
import { LOYALTY_POINTS_MAX_MULTIPLIER, LOYALTY_POINTS_PARENT_CAT_CODE } from '../constants/offer.constants';
import { map } from 'rxjs/operators';

@Injectable()
export class OffersService {

  constructor(
    private http: HttpClient,
    private profileService: ProfileService,
    private permissionService: PermissionService,
    private knownUserService: KnownUserService,
    private configService: ConfigService,
    public auth: AuthService,
    private platformService: PlatformService,
    @Inject(WINDOW) private window: WINDOW
  ) {
  }

  getOffer(id: string, increaseView = 0, usePromise = true, excludeClass = '', returnOfferIfHasSuc = false): Promise<any> | Observable<any> {
    const options = increaseView
      ? { params: new HttpParams().set('increaseView', String(increaseView)).set('excludeClass', String(excludeClass)) }
      : { params: new HttpParams().set('excludeClass', String(excludeClass)) };

    if (returnOfferIfHasSuc) {
      options.params = options.params.append('returnOfferIfHasSuc', String(true));
    }

    const siteTag = this.platformService.currentPlatform.SITE_TAG;
    if (siteTag) {
      options.params = options.params.append('siteTag', siteTag);
    }

    const request = this.auth.hasAccessToken()
      ? this.http.get<OfferModel>(`${environment.apiUrl}/api/offers/${id}`, options)
      : this.http.get<OfferModel>(`${environment.apiUrl}/api/offers/${id}/open-access`, options);

    return usePromise ? request.toPromise() : request;
  }

  isOfferIncludeCode(offer: OfferModel): boolean {
    return offer.code && offer.code.length > 0;
  }

  async getOfferUrl(id: string, param?: DestinationUrlRequestParamsInterface): Promise<any> {
    let params = new HttpParams();
    if (param) {
      Object.entries(param).forEach(([key, value]) => {
        params = params.append(key, value);
      });
    }

    if (this.permissionService.hasDefined('known:access')) {
      let parentGuid = '';
      params = params.append('known_user_email', this.knownUserService.knowUserEmail);

      if (this.knownUserService.knowUserType === KnownUserTypes.CHILD) {
        parentGuid = this.knownUserService.knowUserChildDlk.split('~')[0];
      } else {
        parentGuid = this.knownUserService.knowUserGuid;
        params = params.append('is_parent', 'true');
      }
      params = params.append('known_user_guid', parentGuid);
    }

    return this.http.get<any>(`${environment.apiUrl}/api/offers/${id}/destination-url`, { params }).toPromise();
  }

  public async getOfferLink(offer: OfferModel & SearchResultsTodayDealModel & SearchResultsAdsModel): Promise<string> {
    const originUrl = `${this.window.location.origin}`;
    let offerGuid = offer.guid;
    let destinationUrl;

    if (offer instanceof SearchResultsAdsModel) {
      offerGuid = offer.content_guid;
    }

    if (offer.urlType !== 'static' && !offer.originPrice) {
      const offerData = await this.getOfferUrl(offerGuid);
      destinationUrl = offerData.url || offerData.destinationUrl;
    } else {
      destinationUrl = offer.url || offer.destinationUrl;
    }

    if (destinationUrl && this.isExternalUrl(offer)) {
      if (offer.originPrice) { // originPrice key is exist only in TDD Model (Need to check if offer is today deal)
        return `${originUrl}/home/?tdd_guid=${offerGuid}`;
      } else if (offer.descriptionDetails) {
        return `${originUrl}/offers/${offerGuid}`;
      } else if (!offer.descriptionDetails) {
        return `${originUrl}/home?off_guid=${offerGuid}`;
      }
    } else {
      return '';
    }
  }

  async getOffersByIds({
    ids,
    isDynamicData = false,
    onlyActive = true,
    onlyAvailable = false,
    excludeClasses = [],
    includeClasses = [],
    useImageSelection = false,
    country = ''
  }: {
    ids: string[];
    isDynamicData?: boolean;
    onlyActive?: boolean;
    onlyAvailable?: boolean;
    excludeClasses?: string[];
    includeClasses?: string[];
    useImageSelection?: boolean;
    country?: string;
  }): Promise<SearchOfferForAdsInterface[]> {
    let query = `?ids=${ids.join(',')}`;
    const siteTag = this.platformService.currentPlatform.SITE_TAG;
    query += `&siteTag=${siteTag}`;
    if (onlyActive) {
      query += '&onlyActive=true';
    }
    if (isDynamicData) {
      query += '&isDynamicData=true';
    }
    if (onlyAvailable) {
      query += '&onlyAvailable=true';
    }
    if (excludeClasses.length) {
      query += `&excludeClasses=${excludeClasses.join(',')}`;
    }
    if (includeClasses.length) {
      query += `&includeClasses=${includeClasses.join(',')}`;
    }
    if (useImageSelection) {
      query += '&useImageSelection=1';
    }
    const subdomain = this.profileService.getConfig().getOption<string>('subdomain');
    const countryLast = this.profileService.getOption('countryLast');
    return this.http.get<any>(`${environment.apiUrl}/api/offers/decisions/${subdomain}/${country || countryLast || 'US'}${query}`).toPromise();
  }

  async getOfferAvailableCountries(guid: string): Promise<PlatformCountry[]> {
    return this.http.get<{ countries: PlatformCountry[] }>(`${environment.apiUrl}/api/offers/${guid}/countries`)
      .pipe(
        map(res => res.countries)
      )
      .toPromise();
  }

  async getRelatedCategoryOffers(count: number, offerId: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/offers/${offerId}/related?count=${count}&skipCoupons=true`).toPromise();
  }

  async increaseOfferViewCount(offerId: string): Promise<boolean> {
    return this.http.put<any>(`${environment.apiUrl}/api/offers/${offerId}/view`, null).toPromise();
  }

  public doesOfferIncludeLoyalty(catCodeTagIds: number[] = []): boolean {
    const config = this.configService.getConfig();
    return config.loyalty
      && !!catCodeTagIds.filter(catCode => this.isLoyaltyCode(catCode)).length;
  }

  private isExternalUrl(offer: OfferModel & SearchResultsTodayDealModel): boolean {
    const offerUrl = offer.url || offer.destinationUrl;
    return offerUrl.includes('http:') || offerUrl.includes('https:');
  }

  private isLoyaltyCode(catCode: number): boolean {
    const diff = catCode - LOYALTY_POINTS_PARENT_CAT_CODE;
    return diff > 0 && diff <= LOYALTY_POINTS_MAX_MULTIPLIER;
  }
}
