import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import isEmpty from 'lodash/isEmpty';
import { PermissionService } from '@app/core/auth/services/permission.service';
import { OneTimePasswordService, ContactTypes } from '@app/shared/services/one-time-password.service';
import { ProfileService } from '@app/shared/services/profile/profile.service';
import { KnownUserService } from '@app/shared/services/known-user.service';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import {
  CODE_PATTERN,
  CONTACT_ERROR_LIST,
  EDIT_MODE_ERROR,
  EMAIL_PATTERN,
  PASSCODE_ERROR_LIST,
  PHONE_CODE_PATTERN,
  PHONE_NUMBER_PATTERN,
  ONE_MINUTE,
} from '../vault-otp-modal/vault-otp-modal-data';
import { SessionStorageService } from '@app/widgets/services/session-storage.service';
import { ALLOWED_VIEW_OFFERS, OTP_SENT_KEY } from '@app/shared/constants/one-time-password.constants';
import { OtpReasonTypes } from '@app/shared/enums/otp.enum';
import { OtpData } from '@app/shared/interfaces/otp.interface';
import { RequestThrottleTime } from '@app/shared/enums/request-throttle-time.enum';
import { IRequestThrottleClick } from '@app/shared/interfaces/request-throttle-click.interface';

interface ErrorMessage {
  messageType: 'error' | 'warning' | string;
  messageContent: string;
}

const ACCESS_TOKEN_KEY = 'access_token';
const SHORT_TOKEN_KEY = 'short_token';

@Component({
  selector: 'app-otp-form',
  templateUrl: './otp-form.component.html',
  styleUrls: ['./otp-form.component.less']
})
export class OtpFormComponent implements IRequestThrottleClick, OnInit, OnDestroy {

  @ViewChild('sendCodeButton', { static: true }) sendCodeButton: ElementRef;

  @Input() type: ContactTypes;
  @Input() contact: string;
  @Input() confirmLabel = 'Confirm';
  @Input() sendImmediately = false;
  @Input() isContactEditable = true;
  @Input() disableIfEmpty = false;
  @Input() authenticate = false;
  @Input() offerGuid: string;
  @Input() set customError(error: string) {
    if (!error) {
      return;
    }
    this.setError('error', error);
  }

  @Output() onValidCode = new EventEmitter<string>(); // eslint-disable-line
  @Output() invalidCode = new EventEmitter<void>();

  formSubmitted = false;
  isEditModeOn = true;
  isCodeSent = false;
  timer = 0;
  interval: ReturnType<typeof setTimeout>;
  error: ErrorMessage = {
    messageType: '',
    messageContent: ''
  };
  userCode: number;
  contactInput: string;
  form: UntypedFormGroup;
  contactPhone: string[] = [];
  throttleTime = RequestThrottleTime.OTP;
  passcodeErrors: string[];

  private destroyStream = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private oneTimePasswordService: OneTimePasswordService,
    private profileService: ProfileService,
    private permissionService: PermissionService,
    private knownUserService: KnownUserService,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.registerClickEvent();

    if (this.contact) {
      if (this.type === 'phone') {
        this.contactPhone = this.contact.trim().split('|');
      } else {
        this.contactInput = this.contact;
      }
    }

    this.form = this.fb.group({
      contact: [this.contactInput, [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      phone_code: [this.contactPhone[0], [Validators.required, Validators.pattern(PHONE_CODE_PATTERN)]],
      phone_number: [this.contactPhone[1], [Validators.required, Validators.pattern(PHONE_NUMBER_PATTERN)]],
      password: ['', [
        Validators.required,
        Validators.pattern(CODE_PATTERN)
      ]]
    });

    if (!this.isContactEditable) {
      this.form.controls.contact.disable();
      this.form.controls.phone_code.disable();
    }

    if (this.sendImmediately) {
      const forceResend = !this.sessionStorageService.getItem<boolean>(OTP_SENT_KEY);
      this.sendCode(forceResend);
    }
  }

  ngOnDestroy(): void {
    this.destroyStream.next();
    this.destroyStream.complete();
    clearInterval(this.interval);
  }

  public checkUserCodeOrAuthenticate(): void {
    this.formSubmitted = true;
    this.updatePasscodeErrors();
    this.setError();

    if (this.editModeError() && this.validateField('password', PASSCODE_ERROR_LIST)) {
      const userGuid = this.knownUserService.knowUserGuid || this.profileService.getOption('guid');
      const code = this.form.controls.password.value;
      const email = this.form.controls.contact.value
        || this.profileService.getOption('email')
        || this.knownUserService.knowUserEmail;

      if (!code) {
        this.passcodeErrors = [PASSCODE_ERROR_LIST.required];
        return;
      }

      if (!this.authenticate) {
        this.oneTimePasswordService.checkOtpCode(userGuid, code, email)
          .then(() => {
            clearInterval(this.interval);
            this.sessionStorageService.removeItem(OTP_SENT_KEY);
            this.onValidCode.emit(code);
          })
          .catch((response) => {
            this.invalidCode.emit();
            this.passcodeErrors = [this.oneTimePasswordService.getErrorMessage(response)];
          });
      } else {
        this.oneTimePasswordService.authenticateUserByLoginAndOTP(userGuid, code, email)
          .then((user) => {
            clearInterval(this.interval);

            if (!this.knownUserService.knowUserType.includes('child')) {
              this.knownUserService.clearAll();
              this.knownUserService.clearSkipPassword();
            } else {
              this.sessionStorageService.addValueToArrayByKey<string>(ALLOWED_VIEW_OFFERS, this.offerGuid);
            }

            this.setUserAccess(user);

            this.sessionStorageService.removeItem(OTP_SENT_KEY);
            this.onValidCode.emit(code);
          })
          .catch((response) => {
            this.invalidCode.emit();
            this.passcodeErrors = [this.oneTimePasswordService.getErrorMessage(response)];
          });
      }
    }
  }

  public setUserAccess(user: { scopes: string[]; access_token: string; short_token: string }): void {
    if (user.scopes) {
      this.permissionService.define(user.scopes);
    }
    if (user.access_token) {
      localStorage.setItem(ACCESS_TOKEN_KEY, user.access_token);
    }
    if (user.short_token) {
      localStorage.setItem(SHORT_TOKEN_KEY, user.short_token);
    }
  }

  public sendCode(forceResend = false): void {
    if (this.timer !== 0) {
      return;
    }

    if (this.type === 'email') {
      if (this.validateField('contact', CONTACT_ERROR_LIST, this.type)) {
        this.isCodeSent = true;
        this.generateCode(forceResend);
        this.startTimer();
      }
    } else {
      const isValid = this.validateField('phone_code', CONTACT_ERROR_LIST.phone, 'phone_code') &&
        this.validateField('phone_number', CONTACT_ERROR_LIST.phone, 'phone_number');

      if (isValid) {
        this.isCodeSent = true;
        this.generateCode(forceResend);
        this.startTimer();
      }
    }
  }

  public isErrorShown(): boolean {
    return Boolean(this.error.messageType && this.error.messageContent);
  }

  public isErrorType(): boolean {
    return this.error.messageType === 'error';
  }

  public isWarningType(): boolean {
    return this.error.messageType === 'warning';
  }

  public toogleEditMode(shown = true): void {
    this.setError();
    this.isEditModeOn = shown;

    if (shown) {
      this.form.controls.contact.enable();
      this.form.controls.phone_code.enable();
      this.form.controls.phone_number.enable();
    } else {
      this.form.controls.contact.disable();
      this.form.controls.phone_code.disable();
      this.form.controls.phone_number.disable();
    }
  }

  public registerClickEvent(): void {
    fromEvent(this.sendCodeButton.nativeElement, 'click').pipe(
      throttleTime(this.throttleTime),
      takeUntil(this.destroyStream)
    ).subscribe(_ => this.sendCode(true));
  }

  private editModeError(): boolean {
    if (this.isEditModeOn && !this.isCodeSent) {
      if (this.validateField('contact', CONTACT_ERROR_LIST, this.type)) {
        this.setError('warning', EDIT_MODE_ERROR);
      }

      return false;
    }

    return true;
  }

  private validateField(fieldName: string, errorList = {}, type = ''): boolean {
    const { errors } = this.form.controls[fieldName];
    let message = '';

    errorList = type && !isEmpty(errorList) ? errorList[type] : errorList;

    if (errors && !isEmpty(errors)) {
      Object.keys(errorList).forEach((key) => {
        if (errors[key]) {
          message = errorList[key];
        }
      });

      if (fieldName !== 'password') {
        this.setError('warning', message);
      }

      return false;
    }

    return true;
  }

  private updatePasscodeErrors(): void {
    this.passcodeErrors = this.form.controls.password.errors
      ? Object.keys(this.form.controls.password.errors).map(k => PASSCODE_ERROR_LIST[k] || '')
      : [];
  }

  private setError(type = '', message = ''): void {
    this.error = {
      messageType: type,
      messageContent: message
    };
  }

  private createOtpCode(userGuid: string, type: ContactTypes, contactInfo: string, forceResend: boolean, reason: OtpReasonTypes): void {
    if (userGuid && this.type && contactInfo) {
      const data: OtpData = {
        userGuid,
        type,
        contact: contactInfo,
        activateAccount: false,
        forceResend,
        reason
      };

      this.oneTimePasswordService.createOtpCode(data)
        .then(() => {
          this.sessionStorageService.setItem(OTP_SENT_KEY, true);
        })
        .catch((response) => {
          this.setError('error', this.oneTimePasswordService.getErrorMessage(response));
        });
    }
  }

  private generateCode(forceResend = false): void {
    this.passcodeErrors = [];
    this.setError();

    this.contactInput = this.form.get('contact').value;
    const contactInfo = this.contactInput || this.getPhoneFormData();

    if (this.knownUserService.knowUserGuid) {
      this.createOtpCode(this.knownUserService.knowUserGuid, this.type, contactInfo, forceResend, OtpReasonTypes.subscriptionCenter);
      return;
    }

    this.profileService.profile.pipe(
      takeUntil(this.destroyStream)
    ).subscribe(values => {
      this.createOtpCode(values.guid, this.type, contactInfo, forceResend, OtpReasonTypes.subscriptionCenter);
    });
  }

  private getPhoneFormData(): string {
    return `+${this.form.controls.phone_code.value}${this.form.controls.phone_number.value}`;
  }

  private startTimer(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.timer = ONE_MINUTE;
    this.interval = setInterval(() => {
      if (this.timer === 0) {
        this.isCodeSent = true;
        clearInterval(this.interval);
      } else {
        this.timer--;
      }
    }, 1000);
  }
}
