
<form method="POST"
      class="hotels search-widget {{ customClass }}"
      [class.search-widget-top-indent]="topIndent"
      ngNoForm
      [action]="url">
  <div class="search-widget_header">
    <img class="search-widget_header__logo"
         loading="lazy"
         [src]="widget.icon_url | imageUrl: imageOption">
    <div class="search-widget_header__container">
      <div class="search-widget_header__container__title">
        {{widget.header}}
        <app-offer-badge *ngIf="badgeModel" [offerData]="badgeModel" [offerClass]="badgeModel.class">
        </app-offer-badge>
      </div>
      <button class="rooms-and-guests rooms-desktop-bp"
              (click)="handleRoomsAndGuests($event)"><span class="rooms-desktop-bp__text">{{ roomsAndGuestsTitle
          }}</span>
        <svg width="12"
             height="8"
             [class.rotated-arrow]="showGuestsAndRoomsData"
             [class.standart-arrow]="!showGuestsAndRoomsData"
             viewBox="0 0 12 8"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z"
                fill="#757575" />
        </svg>
      </button>
    </div>
  </div>
  <div class="hotels_search">
    <span class="hotels_search_mask widget_search_mask">Destination{{placeName ? ':' : ''}}</span>
    <app-google-places-autocomplete
                                    classes="hotels-search"
                                    [normalizedDestination]="false"
                                    [wideFormat]="true"
                                    [autocompleteOptions]="autocompleteOptions"
                                    (destinationChange)="updatePlaceName($event)"
                                    (addressChange)="handleAddressChange($event)">
    </app-google-places-autocomplete>
    <input type="hidden"
           name="place_name"
           id="place_name"
           [value]="placeName" />
    <input type="hidden"
           name="place_lat"
           id="place_lat"
           [value]="placeLat" />
    <input type="hidden"
           name="place_lng"
           id="place_lng"
           [value]="placeLng" />

    <div class="hidden-inputs">
      <input type="text"
             name="check_in"
             [value]="checkInDate" />
      <input type="text"
             name="check_out"
             [value]="checkOutDate" />
    </div>
  </div>

  <div class="hotels_dates">
    <div class="hotels_dates_from">
      <span class="hotels_dates_from_mask">From:</span>
      <input placeholder="Check-in"
             aria-label="Check-in"
             autocomplete="off"
             class="hotels_dates_from_input widget-input search-widget-datepickers__check-out--input widget-date-input ngb-datepicker"
             [(ngModel)]="checkIn"
             [minDate]="minDate"
             (dateSelect)="onDateSelection($event, 'checkIn')"
             required="required"
             ngbDatepicker
             (click)="datepickerParser.toggleDatepicker($event, dpCheckIn)"
             #dpCheckIn="ngbDatepicker" />
    </div>
    <div class="hotels_dates_to">
      <span class="hotels_dates_to_mask">To:</span>
      <input placeholder="Check-out"
             aria-label="Check-out"
             autocomplete="off"
             class="hotels_dates_to_input widget-input search-widget-datepickers__check-out--input widget-date-input ngb-datepicker"
             [(ngModel)]="checkOut"
             [minDate]="minDate"
             (dateSelect)="onDateSelection($event, 'checkOut')"
             required="required"
             ngbDatepicker
             (click)="datepickerParser.toggleDatepicker($event, dpCheckOut)"
             #dpCheckOut="ngbDatepicker" />
    </div>
  </div>

  <!-- Hidden wrapper to add rooms data to widget form data -->
  <div class="rooms-wrapper"
       *ngFor="let item of roomFormData | keyvalue">
    <input type="text"
           [name]="item.key"
           [value]="item.value" />
  </div>

  <button class="rooms-and-guests rooms-mobile-bp"
          (click)="handleRoomsAndGuests($event)"><span>{{ roomsAndGuestsTitle }}</span>
    <svg width="12"
         height="8"
         [class.rotated-arrow]="showGuestsAndRoomsData"
         [class.standart-arrow]="!showGuestsAndRoomsData"
         viewBox="0 0 12 8"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z"
            fill="#757575" />
    </svg>
  </button>

  <button class="hotels_button btn btn-primary mini-widget-action__search"
          type="submit"
          (click)="search()">
    <span class="hotels_button_text-xs">SEARCH</span>
    <svg class="hotels_button_text"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M16.6667 14.6667H15.6133L15.24 14.3067C16.5467 12.7867 17.3333 10.8133 17.3333 8.66667C17.3333 3.88 13.4533 0 8.66667 0C3.88 0 0 3.88 0 8.66667C0 13.4533 3.88 17.3333 8.66667 17.3333C10.8133 17.3333 12.7867 16.5467 14.3067 15.24L14.6667 15.6133V16.6667L21.3333 23.32L23.32 21.3333L16.6667 14.6667ZM8.66667 14.6667C5.34667 14.6667 2.66667 11.9867 2.66667 8.66667C2.66667 5.34667 5.34667 2.66667 8.66667 2.66667C11.9867 2.66667 14.6667 5.34667 14.6667 8.66667C14.6667 11.9867 11.9867 14.6667 8.66667 14.6667Z"
            fill="white" />
      <path d="M16.6667 14.6667H15.6133L15.24 14.3067C16.5467 12.7867 17.3333 10.8133 17.3333 8.66667C17.3333 3.88 13.4533 0 8.66667 0C3.88 0 0 3.88 0 8.66667C0 13.4533 3.88 17.3333 8.66667 17.3333C10.8133 17.3333 12.7867 16.5467 14.3067 15.24L14.6667 15.6133V16.6667L21.3333 23.32L23.32 21.3333L16.6667 14.6667ZM8.66667 14.6667C5.34667 14.6667 2.66667 11.9867 2.66667 8.66667C2.66667 5.34667 5.34667 2.66667 8.66667 2.66667C11.9867 2.66667 14.6667 5.34667 14.6667 8.66667C14.6667 11.9867 11.9867 14.6667 8.66667 14.6667Z"
            fill="url(#paint0_linear)" />
      <defs>
        <linearGradient id="paint0_linear"
                        x1="11.66"
                        y1="0"
                        x2="11.66"
                        y2="23.32"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="white" />
          <stop offset="1"
                stop-color="white"
                stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  </button>

  <app-rooms-and-guests *ngIf="showGuestsAndRoomsData"
                        (dismissAction)="dismissRoomPopover()"
                        (roomsAndGuests)="handleRoomsAndGuestsData($event)"
                        [roomsData]="roomsData"
                        [widgetId]="widget.widget_id"
                        [widgetClass]="'hotels-search'">
  </app-rooms-and-guests>
</form>
