<app-system-message></app-system-message>

<app-notification *ngIf="notification?.message"
                  [notification]="notification"
                  (close)="onNotificationClose($event)"
                  (enroll)="onEnrollClick($event)"
                  [@fadeInOutNotification]
                  [wideContainerClass]="wideContainerClass">
</app-notification>

<app-header (showContactUs)="showContactUs($event)"
            [class.is-notification]="notification?.message"
            [customLinksMyProfile]="customLinksMyProfile"
            [customLinksMyOffers]="customLinksMyOffers"
            [wideContainerClass]="wideContainerClass"
            [isFullScreenMap]=isFullScreenMap
            [notificationBanner]="notification?.message"
            [appFocusControl]="'skipToNavigation'">
</app-header>

<ng-container *ngIf="isHomepage">
  <app-zone-section *ngIf="isShowNavigationZoneSection && topZoneSection"
                    [class.listing-map-page]="isFullScreenMap"
                    [class.offer-details-page]="isOfferDetailsPage"
                    [zoneSection]="topZoneSection"
                    [initialTabIndex]="920"
                    (displayed)="this.zonesService.debug($event, 'Rendered')">
  </app-zone-section>
</ng-container>

<main class="content"
      [ngStyle]="{ 'margin-top': mainSectionTopMargin }"
      [class.notification-showing]="notification?.message">
  <router-outlet></router-outlet>
</main>

<app-google-translate-button [class.listing-map-page]="isFullScreenMap"
                             [languageCodes]="translateLanguageCodes"></app-google-translate-button>

<app-footer [canShowFooter]="(infiniteScrollState$ | async) && canShowFooter"
            [country]="country"
            [siteTag]="siteTag"
            [hostName]="hostName"
            [apiHost]="apiHost"
            [cdn]="cdn">
</app-footer>
