/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '@app/shared/models/analytics.event.model';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ComponentHtmlIdRegistryService, WINDOW } from 'g3-common-ui';
import { ConfigService } from '@app/shared/services/config.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FilterStateService } from '@app/shared/services/search/filter-state.service';
import { UntypedFormControl } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { SearchInterceptorService } from '@app/shared/services/search/search-interceptor.service';
import { SearchService } from '@app/shared/services/search/search.service';
import { SearchSuggestedService } from '@app/shared/services/search/search-suggested.service';
import { Subscription } from 'rxjs';

class SearchResults {
  constructor(
    public recent: SearchItem[],
  ) { }

  clear(): void {
    this.recent = [];
  }

}

class SearchItem {
  constructor(
    public q: string,
    public title: string,
  ) { }
}

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.less'],
  providers: [ComponentHtmlIdRegistryService]
})
export class SearchBarComponent implements OnInit, OnDestroy {

  @Input() groupTabIndex: number;

  @Output() clickSearch: EventEmitter<string> = new EventEmitter<string>();

  placeholder = '';
  defaultPlaceholder = 'Search Offers';
  marketplacePlaceholder = '';
  loading = false;
  results: SearchResults;
  details = false;
  blockVisibilityChange = true;
  searchFieldSubscr: Subscription;
  queryParamsSubscr: Subscription;
  searchField: UntypedFormControl;
  inputOnFocus = false;

  constructor(
    public idRegistryService: ComponentHtmlIdRegistryService,
    private searchService: SearchService,
    private router: Router,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private filterStateService: FilterStateService,
    private configService: ConfigService,
    private searchInterceptorService: SearchInterceptorService,
    private searchSuggestedService: SearchSuggestedService,
    @Inject(WINDOW) private window: Window,
  ) {
    this.results = new SearchResults([]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(e: any): void {
    this.setPlaceholderText();
  }

  ngOnInit(): void {
    const marketplaceName = this.configService.getOption<string>('name', '');
    this.marketplacePlaceholder = this.getPlaceholderFromMarketname(marketplaceName);
    this.setPlaceholderText();

    this.queryParamsSubscr = this.route.queryParams.subscribe((params: Params) => {
      const sanitizedSearchQuery = this.searchService.sanitizeSearchQuery(params.q);
      this.searchField = new UntypedFormControl(sanitizedSearchQuery);
    });

    this.searchFieldSubscr = this.searchField.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(term => {
        this.details = false;
        if (term && term.length === 0 && this.inputOnFocus) {
          void this.suggested();
        }
      });
  }

  setPlaceholderText(): void {
    this.placeholder = this.window && this.window.innerWidth >= 1024 ? this.marketplacePlaceholder : this.defaultPlaceholder;
  }

  ngOnDestroy(): void {
    this.searchFieldSubscr.unsubscribe();
    this.queryParamsSubscr.unsubscribe();
  }

  onComponentFocusout(): void {
    if (this.loading) {
      this.blockVisibilityChange = true;
    }

    this.inputOnFocus = false;
  }

  search(e: Event, query = '', title = ''): void {
    e.stopPropagation();
    const isCompleteUrl = ['http', '/'].some(link => query.startsWith(link));

    if (isCompleteUrl) {
      this.hideDetailsAndClear();
      void this.searchInterceptorService.handleRedirect(query, title, true);

      return;
    }

    this.clickSearch.emit('/offers');

    let params = {};
    let q = '';

    if (title && query) {
      this.searchField.setValue(title);

      if (this.searchField.value) {
        q = query;
        params = this.parseQuery(q);
      }
    } else {
      const sanitizedSearchQuery = this.searchService.sanitizeSearchQuery(this.searchField.value);
      this.searchField.setValue(sanitizedSearchQuery);

      if (sanitizedSearchQuery) {
        q = sanitizedSearchQuery;
        params['q'] = sanitizedSearchQuery;
      }
    }

    if (!q || isEqual(this.route.snapshot.queryParams, params)) {
      this.hideDetails();
      return;
    }

    // needs to be cleared, because we are searching not from Collette widget or Seats widget
    this.searchService.clearHiddenTerms();

    if (q && q.length > 2) {
      this.searchService.filter = {};

      void this.router.navigate(['/offers'], { queryParams: params });
      this.details = false;
    }
    this.filterStateService.emitSearchEvent();
  }

  clear(): void {
    this.searchField.setValue('');
  }

  hideDetails(): void {
    this.loading = false;
    this.details = false;
  }

  hideDetailsAndClear(): void {
    this.hideDetails();
    this.clear();
  }

  async suggested(): Promise<void> {
    this.searchService.trackAnalyticsEvents('search-box-open-click', {});
    if (!this.details && (!this.searchField.value || this.searchField.value.length === 0)) {

      this.results.clear();
      this.loading = true;
      this.blockVisibilityChange = false;

      void this.analyticsService.eventTrack(new AnalyticsInternalEventModel('search', {
        type: 'suggested'
      }));

      try {

        const searchData = await this.searchSuggestedService.getSuggested();
        const searchWidgetsData = await this.searchSuggestedService.getSuggestedWidgets();

        this.loading = false;

        if (searchData.recent.length > 0) {

          for (const i of searchData.recent) {
            let query = i.query;
            let title = i.title;

            try {
              title = decodeURIComponent(i.title);
            } catch {
              title = i.title;
            }

            if (i.intercepted) {
              const rule = await this.searchInterceptorService.matchInterceptorRule(i.title);

              if (rule && rule.redirect) {
                query = rule.redirect;
              }
            }

            this.results.recent.push(new SearchItem(query, title));
          }

          if (!this.blockVisibilityChange) {
            this.details = true;
          }
        }

      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    }

  }

  getPlaceholderFromMarketname(marketplaceName: string): string {
    const PlaceholderPrefix = 'Search';
    const MaxPlaceholderLength = 60;

    if (!marketplaceName) {
      return this.defaultPlaceholder;
    }

    let placeholder = `${PlaceholderPrefix} ${marketplaceName}`;
    if (placeholder.length > MaxPlaceholderLength) {
      const words = placeholder.split(' ');

      // Reconstruct placeholder one word at a time until adding the next will exceed character limit
      placeholder = PlaceholderPrefix;
      let i = 1;
      while ((i < words.length) && (placeholder.length + words[i].length + 1 <= MaxPlaceholderLength)) {
        placeholder += ` ${words[i]}`;
        i++;
      }

      if (i === 1) {
        // If the first word of the marketplace name was too lengthy to include, just use the default placeholder
        placeholder = this.defaultPlaceholder;
      } else {
        // Otherwise, append an ellipsis using Unicode character code to escape Angular sanitization
        placeholder += '\u2026';
      }
    }
    return placeholder;
  }

  onInputFocus(): void {
    this.inputOnFocus = true;
    void this.suggested();
  }

  trackRecentAnalytics(title: string, query: string): void {
    this.searchService.trackAnalyticsEvents('search-box-recent-click', { title, query });
  }

  async clearAll(): Promise<void> {
    this.searchService.trackAnalyticsEvents('search-box-clear-all-click', {});
    await this.searchSuggestedService.clearAllSuggestions();
  }

  async clearItem(query: string, title: string, event: { stopPropagation: () => void }): Promise<void> {
    event.stopPropagation();
    this.hideDetails();
    this.searchService.trackAnalyticsEvents('search-box-recent-delete-click', { title, query });
    await this.searchSuggestedService.clearSuggestionItem(query);
  }

  private parseQuery(search: string): any {
    const hashes = search.slice(search.indexOf('?') + 1).split('&');
    const params = {};
    hashes.map(hash => {
      const [key, val] = hash.split('=');
      params[key] = val;
    });

    return params;
  }

}
