/* eslint-disable typescriptESlintPlugin/no-explicit-any*/
import { AnalyticsGAEventModel, AnalyticsInternalEventModel } from '../../models/analytics.event.model';
import { AnalyticsService } from '@shared/services/analytics.service';
import { AuthModalModel } from '@shared/models/auth-modal.model';
import { AuthService } from '@app/core/auth/services/auth.service';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgbActiveModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-hint-modal',
  templateUrl: './auth-hint-modal.component.html',
  styleUrls: ['./auth-hint-modal.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthHintModalComponent implements OnInit {

  @Input() isPermissionFailed: boolean;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();
  @Output() signUpClick: EventEmitter<boolean> = new EventEmitter();
  @Output() loginGuestClick: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('tabset', { static: true }) tabset: NgbNav;

  steps: AuthModalModel[];

  constructor(
    private analyticsService: AnalyticsService,
    private router: Router,
    private activeModal: NgbActiveModal,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.initTabsContent();
    this.handleAnalyticsEvent('show-modal');
  }

  isArrowVisible(arr: string): boolean {
    const tabs = this.tabset.items && this.tabset.items.toArray() || [];
    const activeIndex = tabs.findIndex(tab => (tab.id === this.tabset.activeId));

    return (arr === 'prev') ? !(activeIndex === 0) : !(activeIndex === tabs.length - 1);
  }

  selectTab(step: string): void {
    const tabs = this.tabset.items.toArray();
    const activeIndex = tabs.findIndex(tab => tab.id === this.tabset.activeId);
    const nextIndex = (step === 'next') ? activeIndex + 1 : activeIndex - 1;

    this.tabset.select(tabs[nextIndex].id);

  }

  closeModal(): void {
    this.activeModal.dismiss('CANCEL_MODAL');
    this.modalClose.emit(true);
  }

  tabChange(e: { nextId: string }): void {

    const nextIndex = this.tabset.items.toArray().findIndex(tab => tab.id === e.nextId);
    this.handleAnalyticsEvent('show-tab', {
      index: (nextIndex + 1) // we add +1 because for analytics we need to count tabs from 1
    });
  }

  handleSignUpClick(): void {
    if (this.isPermissionFailed) {
      this.activeModal.dismiss('SIGN_UP');
      this.authService.redirectToSignInDirectly('/'); // Either with a query parameter set: registeringGuest or detect its a guest on auth
    } else {
      this.signUpClick.emit(true);
    }
  }

  handleLoginGuestClick(): void {
    if (this.isPermissionFailed) {
      this.closeModal();
    } else {
      this.loginGuestClick.emit(true);
    }
  }

  handleAnalyticsEvent(action: string, data: any = null): void {

    switch (action) {

      case 'show-modal':

        this.analyticsService.eventsTrack([
          new AnalyticsInternalEventModel('auth', {
            action: 'guest-modal-show-info'
          }),
          new AnalyticsGAEventModel('guest-modal-show-info', {
            category: 'guest-modal'
          })
        ]);

        break;

      case 'show-tab':

        if (data.index) {

          this.analyticsService.eventsTrack([
            new AnalyticsInternalEventModel('auth', {
              action: `guest-modal-show-tab${data.index}`
            }),
            new AnalyticsGAEventModel(`guest-modal-show-tab${data.index}`, {
              category: 'guest-modal'
            })
          ]);

        }

        break;

    }

  }

  initTabsContent(): void {
    this.steps = [
      {
        type: 'list',
        content: {
          title: 'Benefits of Signing Up',
          features: [
            {
              imageClass: 'feature-search',
              description: 'Discover discounts relevant to you with improved search.'
            },
            {
              imageClass: 'feature-save',
              description: 'Save and organize offers so you can find them later.'
            },
            {
              imageClass: 'feature-letter',
              description: 'Manage email subscriptions for your preferences.'
            },
            {
              imageClass: 'feature-delete',
              description: 'Delete your account at any time, no questions asked.'
            }
          ]
        }
      },
      {
        type: 'single',
        content: {
          title: 'Your Privacy Matters',
          imageClass: 'hint-privacy-matters',
          description: 'Your privacy is very important to us. We do not sell your data. We do not buy data about you from third parties. ' +
            'We collect data directly from you for one purpose: to improve your shopping experience and help you save money.'
        }
      }
    ];
    let initialStep: AuthModalModel;
    if (this.isPermissionFailed) {
      initialStep = {
        type: 'single',
        content: {
          title: 'Why can\'t I access this feature?',
          imageClass: 'hint-why-sign-up',
          description: 'This is a secure feature that requires you to be fully authenticated. To unlock secure and personalized features like this, sign in or register.'
        }
      };
    } else {
      initialStep = {
        type: 'single',
        content: {
          title: 'Why Should I Sign Up?',
          imageClass: 'hint-why-sign-up',
          description: 'Sign up to unlock features that make it easier for ' +
            'you to save money on the products you need from the brands you want.'
        }
      };
    }
    this.steps.unshift(initialStep);
  }

}
