export enum PAYLOGIX_PROFILE_KEYS {
  email = 'email',
  participant_id = 'participant_id',
  ssn = 'ssn',
  dob = 'dob',
  employee_id = 'employee_id',
  phone = 'phone',
  zip = 'zip',
  last_name = 'last_name'
}
