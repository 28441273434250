<div>
  <ng-container [ngSwitch]="modalState">
    <div *ngSwitchCase="npsModalState.started">
      <div class="modal-header">
        <div class="header-content">
          <img class="header-content__img"
              loading="lazy"
              src="/assets/images/Img_modal_2x.png"
              alt="">
          <h2 class="header-content__title">
            {{ question }}
          </h2>
        </div>
        <button type="button"
                class="close"
                aria-label="Close"
                (click)="closeModal(); dismissSurvey()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="survey"
            [formGroup]="marksForm"
            (ngSubmit)="submitForm()">
        <div class="modal-body">
          <h4 class="survey-title">Choose score:</h4>
          <div class="survey-error"
              *ngIf="isError && marksForm.get('score').errors?.required">
            Please choose a score first
          </div>
          <div class="survey-scores">
            <div class="btn-group btn-group-toggle survey-scores__block"
                name="radioBasic">
              <label *ngFor="let score of marks"
                    class="survey-scores__block--item"
                    [class.active]="score === selectedMark">
                <input type="radio"
                       formControlName="score"
                       [value]="score"
                       (change)="setSelectedMark(score)">
                {{ score.toString() }}
              </label>
            </div>
          </div>
          <div class="survey-content">
            <p class="survey-content__title">Not at all</p>
            <p class="survey-content__title">Absolutely</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit"
                  class="btn survey-submit">
            Submit
          </button>
        </div>
      </form>
    </div>

    <ng-container *ngSwitchCase="npsModalState.follow_up">
      <div class="modal-header">
        <div class="header-content">
          <img class="header-content__img"
              loading="lazy"
              src="/assets/images/Img_modal_2x.png"
              alt="">
          <h2 class="header-content__feedback-title">
            Thank you for the response!
          </h2>
        </div>
        <button type="button"
                class="close"
                aria-label="Close"
                (click)="closeModal(); dismissSurvey()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="survey"
            [formGroup]="followUpForm"
            (ngSubmit)="submitFollowUpForm()">
        <div class="modal-body survey__feedback">
          <div class="survey__subhead">What is one thing we could do to make your experience even better?</div>

          <div class="survey__textarea">
            <label class="survey__label survey__label--textarea required">Feedback:</label>
            <textarea class="comments-input"
                      data-qa="feedback-input"
                      formControlName="feedback"                                                                                                                                                                                 
                      placeholder="Please add your feedback"></textarea>
            <span *ngIf="surveyErrors?.feedback"
                  class="survey__error input-error"
                  data-qa="feedback-input-error">{{ surveyErrors.feedback }}</span>
          </div>

          <label class="survey__label survey__label--checkbox">
            <input (change)="toggleSurveyContactFields($event.target.checked)"
                   class="survey__checkbox"
                   data-qa="nps-contact-input"
                   formControlName="contact_allow"
                   type="checkbox"/>
            I’d like a representative to contact me.                                                                                  
          </label>
          
          <ng-container *ngFor="let contactField of surveyContactFields">
            <div *ngIf="followUpForm.get(contactField.prop)"
                 class="form-group">
              <label class="input-label required">{{ contactField.name }}</label>
              <input [attr.data-qa]="contactField.dataQA"
                     [formControlName]="contactField.prop"
                     [name]="contactField.prop"
                     [placeholder]="contactField.placeholder"
                     [type]="contactField.type === ControlInputType.Phone ? ControlInputType.Text : contactField.type"
                     autocapitalize="off"
                     class="form-control"/>
              <span *ngIf="surveyErrors[contactField.prop]"
                    [attr.data-qa]="contactField.dataQA + 'error'"
                    class="survey__error input-error">{{ surveyErrors[contactField.prop] }}</span>
            </div>
          </ng-container>

        </div>
        <div class="modal-footer">
          <button type="submit"
                  class="btn survey-submit">
            Submit
          </button>
        </div>
      </form>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="modal-header">
        <div class="header-content">
          <img class="header-content__img end-survey__img"
              loading="lazy"
              src="/assets/images/Img_thanks_2x.png"
              alt="">
          <h2 class="header-content__title">Thank you for the feedback</h2>
        </div>
        <button type="button"
                class="close"
                aria-label="Close"
                (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
