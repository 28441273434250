<button *ngIf="!isGuestOrKnown"
				[appAutoTabIndex]="groupTabIndex"
				(mouseenter)="addHoverClass($event)"
				(mouseleave)="removeHoverClass($event)"
				class="btn btn-icon"
				[class.saved]="isEnrollment ? getEnrollmentSaved() : isSaved"
				[class.stick]="shouldStick"
				[class.white-theme]="isWhiteTheme"
				[class.transparent-theme]="isTransparentTheme"
				[class.bordered]="isBordered"
				(click)="save($event)"
				[attr.aria-label]="isSaved ? 'Remove offer from favorites' : 'Favorite offer'">
	<svg xmlns="http://www.w3.org/2000/svg"
			 xmlns:xlink="http://www.w3.org/1999/xlink"
			 width="18"
			 height="16"
			 viewBox="0 0 16 14">
		<g fill="transparent"
			 fill-rule="evenodd">
			<path class="save-icon"
						fill="currentColor"
						stroke="currentColor"
						d="m6.84,13.104084c-4.12,-3.563 -6.84,-5.913 -6.84,-8.797c0,-2.35 1.936,-4.196 4.4,-4.196c1.392,0 2.728,0.618 3.6,1.595c0.872,-0.977 2.208,-1.595 3.6,-1.595c2.464,0 4.4,1.846 4.4,4.196c0,2.884 -2.72,5.234 -6.84,8.805l-1.16,0.999l-1.16,-1.007z" />
		</g>
	</svg>
	<span class="button-text"
				*ngIf="title">{{title}}</span>
</button>