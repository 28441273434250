/**
 * Mappings from paylogix verification fields names to profile fields names
 */
export const VERIFICATION_PROFILE_FIELDS_MAPPING: Record<string, string> = {
  dob: 'birthday',
  email: 'vendorSettings.paylogixEmail',
  participant_id: 'vendorSettings.paylogixParticipantId',
  ssn: 'vendorSettings.paylogixTokenHash',
  phone: 'vendorSettings.paylogixPhone',
  zip: 'zipCode'
};
