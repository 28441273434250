import { AuthHintModalComponent } from '@shared/components/auth-hint-modal/auth-hint-modal.component';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionService } from '@core/auth/services/permission.service';

@Injectable()
export class PermissionModalService {

  constructor(
    public permissionService: PermissionService,
    private modalService: NgbModal
  ) { }

  showModal(): void {

    const modalRef = this.modalService.open(AuthHintModalComponent, {
      centered: true
    });
    modalRef.componentInstance.isPermissionFailed = true;
    modalRef.result.then((res) => {
    }, ((reason) => {
      // Update handlers
      // const closeReason = this.getDissmissReason(reason);
      // console.log(`checking the reason ${closeReason}`);
      if (reason === 'LOGIN_GUEST') {
        // this.showModalTerms();
      }
    }));
  }

}
