import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import last from 'lodash/last';
import first from 'lodash/first';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ConfigService } from '@shared/services/config.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    public readonly auth: AuthService,
    public readonly router: Router,
    private readonly http: HttpClient,
    private readonly configService: ConfigService,
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const newSubdomain = this.auth.getMovedToSubdomain();
    const isAuthenticated = await this.auth.isAuthenticated();
    if (!isAuthenticated) {

      if (state.url.startsWith('/offers/')) {
        if (state.url.toLowerCase().includes('dlk')) {
          this.auth.redirectToAuthorize();

          return false;
        }

        let guid = last(state.url.split('/'));

        if (guid.includes('?')) {
          guid = first(guid.split('?'));
        }
        const hasOpenAccess = await this.hasOpenAccess(guid);

        if (hasOpenAccess) {
          return true;
        }

        this.auth.redirectToAuthorize();

        return false;
      }

      if (newSubdomain) {
        this.auth.redirectToNewSubdomain(false, newSubdomain);
        return false;
      }
      this.auth.redirectToAuthorize();

      return false;
    }

    // for controlpanel subdomain all links should start with /controlpanel prefix
    if (this.auth.getSubdomain() === 'controlpanel'
      && !(/^\/controlpanel/.test(state.url))) {
      window.location.href = window.location.origin + '/controlpanel';
      return false;
    }

    if (newSubdomain) {
      this.auth.redirectToNewSubdomain(true, newSubdomain);
      return false;
    }

    let requiredPermissions = [];
    if (route) {

      // if we can define current component from route
      // try to use permission from this item
      if (route.component) {
        if (route.data.permissions) {
          requiredPermissions = route.data.permissions;
        }
        // try to use permission from first child item
        // TODO: need to be defined more flexible and better logic here
      } else {
        if (route && route.firstChild && route.firstChild.data && route.firstChild.data.permissions) {
          requiredPermissions = route.firstChild.data.permissions;
        }
      }
    }

    // check if is defined permissions for this route
    if (Array.isArray(requiredPermissions) && requiredPermissions.length > 0) {
      const hasDefined = this.auth.permissionService.hasAnyDefined(requiredPermissions);
      if (!hasDefined) {
        void this.router.navigate(['/home']);
        return false;
      }
    }

    return true;
  }

  private async hasOpenAccess(guid: string): Promise<boolean> {
    const response = await this.http
      .get<{ has_open_access: boolean }>(`${environment.apiUrl}/api/offers/${guid}/has-open-access`)
      .toPromise();

    return response.has_open_access;
  }
}
