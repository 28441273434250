import {
  Component,
  Input,
  NgZone,
  OnChanges
} from '@angular/core';

const VERSION = '202011131416';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnChanges {

  @Input() country: string;
  @Input() siteTag: string;
  @Input() hostName: string;
  @Input() apiHost: string;
  @Input() cdn: string;
  @Input() canShowFooter: boolean;

  public loaded = false;

  constructor(
    private _ngZone: NgZone
  ) { }

  get wrapperOpacity(): string {
    return this.loaded ? '1' : '0';
  }

  ngOnChanges(): void {
    if (this.canShowFooter) {
      this._ngZone.runOutsideAngular(() => {
        const link = window.document.createElement('link');
        link.href = `${this.apiHost}/assets/footer/style.css`;
        link.rel = 'stylesheet';
        link.type = 'text/css';

        const dataDiv = window.document.createElement('div');
        dataDiv.id = 'bundleId';
        dataDiv.dataset.hostname = this.hostName;
        dataDiv.dataset.country = this.country;
        dataDiv.dataset.cdn = this.cdn;
        dataDiv.dataset.siteTag = this.siteTag;

        const script = window.document.createElement('script');
        script.src = `${this.apiHost}/assets/footer/bundle.js`;
        window.document.body.appendChild(dataDiv);
        window.document.body.appendChild(link);
        window.document.body.appendChild(script);
        script.onload = (): void => {
          this.loaded = true;
        };
      });
    }
  }
}
