<div class='card-content-wrapper'
     (click)="handleCtaClick($event)"
     [ngClass]='{ "card-content-blurred": isAccessLockVisible, "no-logo": !ad?.vendorImage }'>
  <ng-container *ngIf="!isProductCard; else productCardTemplate">
    <div *ngIf="ad.isGeneratedAd && ad.guid"
         class="decision-wrapper b-ad-design card-wrapper mobile-design-1"
         [class.move-up-effect]="isEnrollment"
         [class.paylogix]="isPaylogixOffer"
         (mouseleave)="onMouseLeave()"
         [class.hover-out]="mouseJustLeft"
         [appAutoTabIndex]="groupTabIndex"
         role="presentation">

      <ng-container *ngIf="!ad.paylogixSubscription">
        <app-save-button appProfileFeatures
                         [isTransparentTheme]="isTier1"
                         [guid]="ad.guid"
                         [isSaved]="isSavedOffer"
                         [isSaved$]="isSaved$"
                         [isEnrollment]="isEnrollment"
                         [appHasPermission]="'offer:favorites' || 'offer:save'"
                         (permissionConfirmed)="markOffer($event, ad)"
                         [groupTabIndex]="groupTabIndex"
                         class="small-heart-icon">
        </app-save-button>

        <app-offer-badge *ngIf="!ad.paylogixSubscription"
                         [offerData]="ad"
                         [offerClass]="ad.class.toLowerCase() === 'client' ? ad.class : ''"
                         [withRightCorners]="true"
                         [isPaylogixOffer]="ad.isPaylogixAd"
                         class="position-right offer-badge">
        </app-offer-badge>

        <!-- #comment='using ad.guid to ensure that the datbase has loaded the data'-->
        <img alt=""
             role="presentation"
             src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
             appProgressiveImageLoader
             [trySmall]="true"
             [srcLarge]="imageUrl | imageUrl : getImageOptions() : false"
             class="brand-logo sel-ad-image"
             (imageLoaded)="handleImageLoad($event)">

        <img *ngIf="ad?.vendorImage"
             [src]="ad?.vendorImage | imageUrl : getLogoOptions()"
             [alt]="ad?.company"
             [class.large]="isTier1"
             [class.paylogix-not-enrolled-logo]="isPaylogixOffer && isOfferNotEnrolled"
             loading="lazy"
             class="card-vendor-logo"
             #testVendorLogoImage
             [hidden]="testVendorLogoImage.isBrokenLink"
             (error)="testVendorLogoImage.isBrokenLink = true">

        <app-disclaimer-button *ngIf="ad.descriptionDisclaimer"
                               tooltipText="Click/tap to see more information"
                               placement="top"
                               (openDisclaimer)="openPreviewModal()"
                               class="ad-sub-heading-disclaimer"
                               [class.paylogix-tier-1-disclaimer]="isPaylogixOffer && isTier1">
        </app-disclaimer-button>

        <h5 role="presentation"
            class="sel-ad-title sel-ad-title--multi-line"
            [class.card-with-input]="isOfferCardHasInputField"
            [class.paylogix-not-enrolled-title]="isPaylogixOffer && isOfferNotEnrolled"
            [class.with-description]="isPaylogixOffer && isOfferNotEnrolled && paylogixProductDetails && paylogixProductDetails.length && !isTier1"
            [innerHTML]="paylogixProductDetails && paylogixProductDetails.length && ad.paylogix_enrolled_header_short || ad.titleShort">
        </h5>

        <div *ngIf="isUserEnrolledPaylogix && isPaylogixOffer && !isTier1"
             class="ad-paylogix-description-gradient-backdrop"></div>

        <div *ngIf="isUserEnrolledPaylogix && isPaylogixOffer"
             class="ad-paylogix-description">
          <div class="ad-paylogix-description-info">
               <span *ngFor="let product of paylogixProductDetails"
                    class="ad-item__paylogix-not-enrolled__center__details__item">
                {{ product }}
              </span>
          </div>
        </div>

        <div class="bottom-content-wrapper"
             [class.bottom-content-wrapper_right]="!isShowCountViews()"
             [class.paylogix-complete-info]="!ad.descriptionDetails && isPaylogixDataIncomplete"
             [class.paylogix-offer-button]="isUserEnrolledPaylogix && isPaylogixOffer"
             [class.paylogix-not-enrolled-bottom-wrapper]="isPaylogixOffer && isOfferNotEnrolled"
             role="presentation">
          <div *ngIf="isShowCountViews()"
               class="views-counter"
               role="presentation"
               aria-hidden="true">
            <img class="views-icon"
                 src="/assets/icons/eye.svg"
                 alt="views"
                 role="presentation">
            {{ ad.views | bigNumber }}
          </div>

          <div [ngClass]="{ 'direct-click-out--cta': !isPaylogixDataIncomplete }"
               class="direct-click-out"
               role="presentation"
               aria-hidden="true"
               (click)="handleCtaClick($event, AdItemClick.CTA)">
            <app-ad-paylogix-button *ngIf="ad.isPaylogixAd && isPaylogixDataIncomplete else ctaButton">
            </app-ad-paylogix-button>

            <ng-template #ctaButton>
              <app-ad-cta-button *ngIf='!ad?.isAuthRequired'
                                 [options]="adCtaButtonOptions">
              </app-ad-cta-button>
            </ng-template>
          </div>

        </div>

        <div class="bottom-content-wrapper card-with-input"
             role="presentation">
          <div class="input-container">
            <div class="input-with-btn-inside">
              <input #inputField
                     [(ngModel)]="cardInput"
                     type="text"
                     maxlength="5"
                     placeholder="Zip Code"
                     (click)="onInputClick($event)"
                     [appInputRegexValidator]="'[^A-Za-z0-9]'"
                     (keyup)="changeFocus(cardInput.length)">
              <button #inputCardButton
                      class="btn btn-primary"
                      [class.selected]="isSelected"
                      (click)="handleCtaClick($event, AdItemClick.CTACard)">
                <span class="input-card-button-label">{{((ad.ctaButtonTitle || '') | truncateCompleteWords: 8) ||
                  'SEARCH'}}</span>
              </button>
            </div>
          </div>
        </div>
      </ng-container>

      <div *ngIf="isOfferNotEnrolled && !isPaylogixDataIncomplete"
            class="ad-item__not-enrolled-pill btn btn-text"
            [class.no-deduction]="!deductionPillShown">
        <span class="ad-item__not-enrolled-pill__text">Not enrolled</span>
      </div>
      <ng-container *ngIf="ad.paylogixSubscription">
        <img *ngIf="isTier1"
             alt=""
             role="presentation"
             [src]="ad.dbImageUrl"
             class="brand-logo sel-ad-image">
        <div class="ad-item__paylogix-enrollment">
          <div class="ad-item__paylogix-enrollment__top"
               [class.deduction-pill-shown]="deductionPillShown">
            <img *ngIf="ad?.vendorImage"
                 [src]="ad?.vendorImage | imageUrl : getLogoOptions()"
                 [alt]="ad?.company"
                 loading="lazy"
                 class="card-vendor-logo"
                 #testVendorLogoImage
                 [hidden]="testVendorLogoImage.isBrokenLink"
                 (error)="testVendorLogoImage.isBrokenLink = true">
            <div class="ad-item__paylogix-enrollment__top__pills top-pills-container">
              <div *ngIf="deductionPillShown"
                   class="ad-item__payroll-deduction-pill btn btn-text">
                <div>
                  Payroll<span class="ad-item__payroll-deduction-pill__part-2"> deduction</span>
                </div>
              </div>
              <div class="ad-item__payroll-enrolled-pill dark-pill" [class.no-deduction]="!deductionPillShown">
                <div>
                  Enrolled
                </div>
              </div>
            </div>
          </div>
          <div class="ad-item__paylogix-enrollment__center">
            <h5 class="ad-item__paylogix-enrollment__center__title sel-ad-title sel-ad-title--paylogix"
                [innerHTML]="paylogixProductDetails && paylogixProductDetails.length && ad.paylogix_enrolled_header_short || ad.titleShort"></h5>
            <div *ngIf="paylogixProductDetails.length" class="ad-item__paylogix-enrollment__center__details">
              <span *ngFor="let product of paylogixProductDetails"
                    class="ad-item__paylogix-enrollment__center__details__item">
                {{ product }}
              </span>
            </div>
          </div>
          <div class="ad-item__paylogix-enrollment__bottom">
            <app-ad-cta-button [options]="manageCtaOptions"
                               class="ad-item__paylogix-enrollment__manage_btn"
                               (click)="onPaylogixAdClicked($event)">
            </app-ad-cta-button>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="decision-wrapper adzerk-data card-wrapper"
         *ngIf="!ad.isGeneratedAd && !(ad.isHtmlTemplate)">
      <img [src]="ad?.imageUrl"
           loading="lazy"
           class="sel-ad-image"
           alt="">
    </div>

    <div class="decision-wrapper adzerk-data card-wrapper"
         *ngIf="ad.isHtmlTemplate">
      <div class="custom-adzerk-template"
           [innerHtml]="(ad?.template || '')"></div>
    </div>
  </ng-container>
  <app-card-access-lock *ngIf='ad?.isAuthRequired'
                        [isTier1]="isTier1"
                        (visibilityChange)="onAccessLockVisibilityChange($event)"
                        [isSignedInAsUnconfirmedUser]="isSignedInAsKnownUser"
                        [showPassword]="showPassword"
                        [showConfirm]="showConfirm"
                        [offerGuid]="ad.contentGuid"
                        [offer]="ad"
                        (accessGranted)="handleCtaClick(null)">
  </app-card-access-lock>
</div>

<!-- ----------Product Card Template----------- -->

<ng-template #productCardTemplate>
  <div *ngIf="ad.isGeneratedAd && ad.guid"
       class="decision-wrapper b-ad-design card-wrapper mobile-design-1 product-card"
       (mouseleave)="onMouseLeave()"
       [class.hover-out]="mouseJustLeft"
       [appAutoTabIndex]="groupTabIndex"
       role="presentation">

    <app-offer-badge *ngIf="!ad.paylogixSubscription"
                     [offerData]="ad"
                     [withRightCorners]="true"
                     [isPaylogixOffer]="ad.isPaylogixAd"
                     class="position-right offer-badge">
    </app-offer-badge>

    <!-- #comment='using ad.guid to ensure that the datbase has loaded the data'-->
    <img alt=""
         role="presentation"
         src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
         appProgressiveImageLoader
         [trySmall]="true"
         [srcLarge]="ad.dbImageUrl | imageUrl : getImageOptions() : false"
         class="brand-logo sel-ad-image product-image"
         (imageLoaded)="handleImageLoad($event)">

    <div class="ad-product-content">
      <div class="ad-top-header">
        <p role="presentation"
           class="notranslate ad-top-header-company-name"
           [class.card-with-input]="isOfferCardHasInputField"
           [innerHTML]="brandTitle">
        </p>
        <div class="buttons-container">
          <app-share-button *ngIf="brandTitle && ad.headerShort && ad.destinationUrl"
                            tooltipText="Offer info copied to clipboard"
                            placement="top"
                            (shareClick)="shareClick(ad)"
                            class="btn-save-small btn-share btn-share-product">
          </app-share-button>
          <app-disclaimer-button *ngIf="ad.descriptionDisclaimer"
                                 tooltipText="Click/tap to see more information"
                                 placement="top"
                                 (openDisclaimer)="openPreviewModal()"
                                 class="ad-sub-heading-disclaimer">
          </app-disclaimer-button>
        </div>
      </div>

      <h5 role="presentation"
          class="ad-main-title"
          [class.card-with-input]="isOfferCardHasInputField"
          [innerHTML]="ad.headerShort">
      </h5>

      <div *ngIf="ad.productSubHeading"
           class="ad-sub-heading">
        <div class="ad-sub-heading-info"
             [innerHTML]="ad.productSubHeading">
        </div>
      </div>
    </div>

    <div class="ad-action">
      <button (click)="handleCtaClick($event, AdItemClick.CTA)"
              class="btn btn-primary ad-action-desktop-btn">
        {{ getProductCtaTitle() }}
        <svg *ngIf="!ad.code"
             _ngcontent-mxt-c39=""
             fill="none"
             height="16"
             viewBox="0 0 16 16"
             width="16"
             xmlns="http://www.w3.org/2000/svg"
             class="ng-star-inserted">
          <path _ngcontent-mxt-c39=""
                d="M1 11.75c0 .423.025.84.073 1.25.637-4.344 4.02-4.085 9.312-4.103v2.41L15 6.653 10.385 2v2.411C5.12 4.411 1 5.703 1 11.751z">
          </path>
          <path _ngcontent-mxt-c39=""
                d="M1 11.75c0 .423.025.84.073 1.25.637-4.344 4.02-4.085 9.312-4.103v2.41L15 6.653 10.385 2v2.411C5.12 4.411 1 5.703 1 11.751z">
          </path>
          <defs _ngcontent-mxt-c39="">
            <linearGradient _ngcontent-mxt-c39=""
                            gradientUnits="userSpaceOnUse"
                            id="a"
                            x1="8"
                            x2="8"
                            y1="13"
                            y2="2">
              <stop _ngcontent-mxt-c39=""
                    stop-color="#fff"></stop>
              <stop _ngcontent-mxt-c39=""
                    offset="1"
                    stop-color="#fff"
                    stop-opacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>

        <svg *ngIf="ad.code"
             _ngcontent-pqg-c39=""
             fill="none"
             height="16"
             viewBox="0 0 16 16"
             width="16"
             xmlns="http://www.w3.org/2000/svg"
             class="ng-star-inserted">
          <path _ngcontent-pqg-c39=""
                clip-rule="evenodd"
                d="M4.246 6.626L5.59 7.99 4.246 9.353A2.38 2.38 0 0 0 1 11.428v.14a2.382 2.382 0 1 0 4.685-.606l1.59-1.27.695.705a.303.303 0 0 0 .217.091h4.025a.112.112 0 0 0 .07-.2L9.403 7.99l4.138-3.31a.367.367 0 0 0 .035-.535 2.679 2.679 0 0 0-2.385-.793A3.815 3.815 0 0 0 9.114 4.43L7.279 6.282l-1.59-1.271a2.384 2.384 0 0 0 .055-.933A2.382 2.382 0 0 0 1 4.41v.14a2.38 2.38 0 0 0 3.246 2.075zm5.845 5.92v-.549a.594.594 0 0 1 .594-.595h1.224a.594.594 0 0 1 .594.595v.55a.594.594 0 0 1-.594.594h-1.226a.594.594 0 0 1-.592-.595zm-1.529-1.143H7.34l-.002-.001a.594.594 0 0 0-.594.595v.55a.594.594 0 0 0 .594.594h1.224a.594.594 0 0 0 .594-.595v-.549a.594.594 0 0 0-.594-.594zm-5.182 1.1a.94.94 0 0 0 .94-.939l.001.005a.94.94 0 1 0-1.88-.005c0 .519.42.94.94.94zm.94-8.095a.94.94 0 1 1-.274-.663.94.94 0 0 1 .275.666l-.001-.003zm10.935 6.994H14.03a.594.594 0 0 0-.593.595v.55a.594.594 0 0 0 .593.594h1.225a.594.594 0 0 0 .594-.595v-.549a.594.594 0 0 0-.594-.595z"
                fill-rule="evenodd"></path>
          <path _ngcontent-pqg-c39=""
                clip-rule="evenodd"
                d="M4.246 6.626L5.59 7.99 4.246 9.353A2.38 2.38 0 0 0 1 11.428v.14a2.382 2.382 0 1 0 4.685-.606l1.59-1.27.695.705a.303.303 0 0 0 .217.091h4.025a.112.112 0 0 0 .07-.2L9.403 7.99l4.138-3.31a.367.367 0 0 0 .035-.535 2.679 2.679 0 0 0-2.385-.793A3.815 3.815 0 0 0 9.114 4.43L7.279 6.282l-1.59-1.271a2.384 2.384 0 0 0 .055-.933A2.382 2.382 0 0 0 1 4.41v.14a2.38 2.38 0 0 0 3.246 2.075zm5.845 5.92v-.549a.594.594 0 0 1 .594-.595h1.224a.594.594 0 0 1 .594.595v.55a.594.594 0 0 1-.594.594h-1.226a.594.594 0 0 1-.592-.595zm-1.529-1.143H7.34l-.002-.001a.594.594 0 0 0-.594.595v.55a.594.594 0 0 0 .594.594h1.224a.594.594 0 0 0 .594-.595v-.549a.594.594 0 0 0-.594-.594zm-5.182 1.1a.94.94 0 0 0 .94-.939l.001.005a.94.94 0 1 0-1.88-.005c0 .519.42.94.94.94zm.94-8.095a.94.94 0 1 1-.274-.663.94.94 0 0 1 .275.666l-.001-.003zm10.935 6.994H14.03a.594.594 0 0 0-.593.595v.55a.594.594 0 0 0 .593.594h1.225a.594.594 0 0 0 .594-.595v-.549a.594.594 0 0 0-.594-.595z"
                fill-rule="evenodd"></path>
          <defs _ngcontent-pqg-c39="">
            <linearGradient _ngcontent-pqg-c39=""
                            gradientUnits="userSpaceOnUse"
                            id="a"
                            x1="8.424"
                            x2="8.424"
                            y1="2"
                            y2="13.944">
              <stop _ngcontent-pqg-c39=""
                    stop-color="#fff"></stop>
              <stop _ngcontent-pqg-c39=""
                    offset="1"
                    stop-color="#fff"
                    stop-opacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>
      </button>
      <a (click)="handleCtaClick($event, AdItemClick.CTA)"
         class="btn btn-text ad-action-mobile-btn">
        <span class="ad-action-mobile-btn-title">
          {{ getProductCtaTitle() }}
        </span>
        <svg *ngIf="!ad.code"
             _ngcontent-mxt-c39=""
             fill="none"
             height="16"
             viewBox="0 0 16 16"
             width="16"
             xmlns="http://www.w3.org/2000/svg"
             class="ng-star-inserted">
          <path _ngcontent-mxt-c39=""
                d="M1 11.75c0 .423.025.84.073 1.25.637-4.344 4.02-4.085 9.312-4.103v2.41L15 6.653 10.385 2v2.411C5.12 4.411 1 5.703 1 11.751z">
          </path>
          <path _ngcontent-mxt-c39=""
                d="M1 11.75c0 .423.025.84.073 1.25.637-4.344 4.02-4.085 9.312-4.103v2.41L15 6.653 10.385 2v2.411C5.12 4.411 1 5.703 1 11.751z">
          </path>
          <defs _ngcontent-mxt-c39="">
            <linearGradient _ngcontent-mxt-c39=""
                            gradientUnits="userSpaceOnUse"
                            id="a"
                            x1="8"
                            x2="8"
                            y1="13"
                            y2="2">
              <stop _ngcontent-mxt-c39=""
                    stop-color="#fff"></stop>
              <stop _ngcontent-mxt-c39=""
                    offset="1"
                    stop-color="#fff"
                    stop-opacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>

        <svg *ngIf="ad.code"
             _ngcontent-pqg-c39=""
             fill="none"
             height="16"
             viewBox="0 0 16 16"
             width="16"
             xmlns="http://www.w3.org/2000/svg"
             class="ng-star-inserted">
          <path _ngcontent-pqg-c39=""
                clip-rule="evenodd"
                d="M4.246 6.626L5.59 7.99 4.246 9.353A2.38 2.38 0 0 0 1 11.428v.14a2.382 2.382 0 1 0 4.685-.606l1.59-1.27.695.705a.303.303 0 0 0 .217.091h4.025a.112.112 0 0 0 .07-.2L9.403 7.99l4.138-3.31a.367.367 0 0 0 .035-.535 2.679 2.679 0 0 0-2.385-.793A3.815 3.815 0 0 0 9.114 4.43L7.279 6.282l-1.59-1.271a2.384 2.384 0 0 0 .055-.933A2.382 2.382 0 0 0 1 4.41v.14a2.38 2.38 0 0 0 3.246 2.075zm5.845 5.92v-.549a.594.594 0 0 1 .594-.595h1.224a.594.594 0 0 1 .594.595v.55a.594.594 0 0 1-.594.594h-1.226a.594.594 0 0 1-.592-.595zm-1.529-1.143H7.34l-.002-.001a.594.594 0 0 0-.594.595v.55a.594.594 0 0 0 .594.594h1.224a.594.594 0 0 0 .594-.595v-.549a.594.594 0 0 0-.594-.594zm-5.182 1.1a.94.94 0 0 0 .94-.939l.001.005a.94.94 0 1 0-1.88-.005c0 .519.42.94.94.94zm.94-8.095a.94.94 0 1 1-.274-.663.94.94 0 0 1 .275.666l-.001-.003zm10.935 6.994H14.03a.594.594 0 0 0-.593.595v.55a.594.594 0 0 0 .593.594h1.225a.594.594 0 0 0 .594-.595v-.549a.594.594 0 0 0-.594-.595z"
                fill-rule="evenodd"></path>
          <path _ngcontent-pqg-c39=""
                clip-rule="evenodd"
                d="M4.246 6.626L5.59 7.99 4.246 9.353A2.38 2.38 0 0 0 1 11.428v.14a2.382 2.382 0 1 0 4.685-.606l1.59-1.27.695.705a.303.303 0 0 0 .217.091h4.025a.112.112 0 0 0 .07-.2L9.403 7.99l4.138-3.31a.367.367 0 0 0 .035-.535 2.679 2.679 0 0 0-2.385-.793A3.815 3.815 0 0 0 9.114 4.43L7.279 6.282l-1.59-1.271a2.384 2.384 0 0 0 .055-.933A2.382 2.382 0 0 0 1 4.41v.14a2.38 2.38 0 0 0 3.246 2.075zm5.845 5.92v-.549a.594.594 0 0 1 .594-.595h1.224a.594.594 0 0 1 .594.595v.55a.594.594 0 0 1-.594.594h-1.226a.594.594 0 0 1-.592-.595zm-1.529-1.143H7.34l-.002-.001a.594.594 0 0 0-.594.595v.55a.594.594 0 0 0 .594.594h1.224a.594.594 0 0 0 .594-.595v-.549a.594.594 0 0 0-.594-.594zm-5.182 1.1a.94.94 0 0 0 .94-.939l.001.005a.94.94 0 1 0-1.88-.005c0 .519.42.94.94.94zm.94-8.095a.94.94 0 1 1-.274-.663.94.94 0 0 1 .275.666l-.001-.003zm10.935 6.994H14.03a.594.594 0 0 0-.593.595v.55a.594.594 0 0 0 .593.594h1.225a.594.594 0 0 0 .594-.595v-.549a.594.594 0 0 0-.594-.595z"
                fill-rule="evenodd"></path>
          <defs _ngcontent-pqg-c39="">
            <linearGradient _ngcontent-pqg-c39=""
                            gradientUnits="userSpaceOnUse"
                            id="a"
                            x1="8.424"
                            x2="8.424"
                            y1="2"
                            y2="13.944">
              <stop _ngcontent-pqg-c39=""
                    stop-color="#fff"></stop>
              <stop _ngcontent-pqg-c39=""
                    offset="1"
                    stop-color="#fff"
                    stop-opacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>
      </a>
    </div>
    <app-card-access-lock *ngIf='ad?.isAuthRequired'
                          [isTier1]="isTier1"
                          [showPassword]="showPassword"
                          [showConfirm]="showConfirm"
                          [isSignedInAsUnconfirmedUser]="isSignedInAsKnownUser && !isUnconfirmedKnownUser"
                          [offerGuid]="ad.contentGuid"
                          [offer]="ad"
                          (accessGranted)="handleCtaClick(null)">
    </app-card-access-lock>
  </div>
</ng-template>
