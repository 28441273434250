import { Injectable } from '@angular/core';
import { AdobeDataCollectorService } from '../adobe-data-collector.service';
import { OffersService } from '../../offers.service';

@Injectable()
export class AdobeOfferDetailspageDataService {

  constructor(
    private adobeDataCollectorService: AdobeDataCollectorService,
    private offersService: OffersService
  ) { }

  async setDetailsPageData(path: string): Promise<string> {

    const offerGuid = path.split('/').pop();
    const offer = await this.offersService.getOffer(offerGuid, 1, true, 'Pass-Thru');

    if (offer && offer.status === 'active') {
      await this.adobeDataCollectorService.setPageData({
        name: `offer detail: ${offer.vendor_brand.title} | ${offer.header_short}`,
        section: 'offer detail',
        type: 'g3 offer details',
        hierarchy: this.adobeDataCollectorService.setHierarchy('Offer Details Page'),
      });
    }

    return offer.status;
  }
}
