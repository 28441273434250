import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  public constructor(
    private readonly router: Router,
  ) { }

  // This guard is a hack for redirecting to other root path keeping rest of URL
  async canActivate(route: ActivatedRouteSnapshot, nextRoute: RouterStateSnapshot): Promise<boolean> {
    const currentPath = route?.routeConfig?.path || '';
    const redirectToPath = route?.data?.redirectToPath || '';

    if (currentPath && redirectToPath) {
      const urlToReplace = nextRoute.url.replace(currentPath, redirectToPath);
      await this.router.navigateByUrl(urlToReplace);
    }

    return false;
  }
}
