/* eslint-disable typescriptESlintPlugin/explicit-module-boundary-types */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HomepageAdsService } from '@app/shared/services/homepage-ads.service';
import { AdobeAnalyticsEventsService } from '@shared/services/adobe/units/adobe-analytics-events.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import { OpenEnrollmentService } from '@shared/services/open-enrollment/open-enrollment.service';
import { NotiBlockItem } from '@zones/interfaces/noti-block.interface';
import { ZoneSectionElement } from '@zones/interfaces/zones-search-response.interface';
import {
  ZonesSectionElementsAnalyticsService
} from '@zones/services/analytics/zones-section-elements-analytics.service';
import { NotiBlockService } from '@zones/services/noti-block.service';
import { ZoneHeaderService } from '@zones/services/zone-header.service';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-noti-block',
  templateUrl: './noti-block.component.html',
  styleUrls: ['./noti-block.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotiBlockComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public data: ZoneSectionElement;
  @Input() public groupTabIndex: number;

  public notiBlockItem: NotiBlockItem;
  public isShowNotiBlock = false;
  public anchorLink = '';
  public isEnrollmentEnabled = false;
  public isClosed = false;

  private isShowNotiBlockStream$ = new Subject<void>();
  private destroyStream$ = new Subject<void>();

  constructor(
    private notiBlockService: NotiBlockService,
    private zonesSectionsAnalyticsService: ZonesSectionElementsAnalyticsService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private homepageAdsService: HomepageAdsService,
    private zoneHeaderService: ZoneHeaderService,
    private openEnrollmentService: OpenEnrollmentService,
    private cdr: ChangeDetectorRef,
    private adobeAnalyticsEventsService: AdobeAnalyticsEventsService
  ) { }

  public get hasContent(): boolean {
    const content = this.notiBlockItem?.body || this.notiBlockItem?.header;
    return !!content;
  }

  public async ngOnInit(): Promise<void> {
    this.notiBlockItem = this.notiBlockService.getNotiBlock(this.data);
    const isNotiStripItem = this.notiBlockItem?.style === 'noti_strip';
    const isHomepage = this.isHomepage();

    if (isNotiStripItem) {
      const data = await this.openEnrollmentService.getEnrollmentData();
      if (data) {
        this.isEnrollmentEnabled = true;
      }
    }

    this.isShowNotiBlockStream$
      .pipe(
        filter(() => this.isShowNotiBlock && !!this.data),
        takeUntil(this.destroyStream$)
      )
      .subscribe(() => {
        this.analyticsService.eventsTrack(
          this.notiBlockService.getAnalyticsEvents('displayed', this.data, this.notiBlockItem)
        );
        this.adobeAnalyticsEventsService.sendNotiImpression(this.data);
      });

    this.router.events.pipe(takeUntil(this.destroyStream$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        const isHomepage = this.isHomepage();
        this.handleNotiBlockShown(isNotiStripItem, isHomepage);
        this.cdr.detectChanges();
      }
    });

    this.handleNotiBlockShown(isNotiStripItem, isHomepage);

    if (this.data && this.data.name) {
      const sectionName = `s_${this.data.name}`;
      this.anchorLink = this.homepageAdsService.getAnchorLink(sectionName);
    } else {
      this.anchorLink = 's_noti';
    }
    this.cdr.detectChanges();
  }

  public ngAfterViewInit(): void {
    this.homepageAdsService.scrollToAnchor(this.anchorLink);
  }

  public ngOnDestroy(): void {
    this.destroyStream$.next();
    this.destroyStream$.complete();
  }

  public isHomepage(): boolean {
    return this.router.url.startsWith('/home');
  }

  public handleNotiBlockShown(isNotiStripItem, isHomepage): void {
    if (this.isClosed) {
      return;
    }
    if (isNotiStripItem && isHomepage && this.isEnrollmentEnabled) {
      this.isShowNotiBlock = false;
      this.isShowNotiBlockStream$.next();
      this.notiBlockService.updateNotiBlockState(this.isShowNotiBlock);
    } else {
      this.isShowNotiBlock = this.notiBlockItem ? this.notiBlockService.isItemAvailablePerSession(this.notiBlockItem) : false;
      this.isShowNotiBlockStream$.next();
      this.notiBlockService.updateNotiBlockState(this.isShowNotiBlock);
      if (isNotiStripItem) {
        this.notiBlockService.updateNotiBannerState(this.isShowNotiBlock);
      }
    }
  }

  public async close(e): Promise<void> {
    e.stopPropagation();

    this.isShowNotiBlock = false;
    this.isClosed = true;
    this.isShowNotiBlockStream$.next();
    this.notiBlockService.updateNotiBlockState(this.isShowNotiBlock);
    this.notiBlockService.updateNotiBannerState(this.isShowNotiBlock);

    await this.notiBlockService.handleDismiss(this.notiBlockItem);

    this.analyticsService.eventsTrack(
      this.notiBlockService.getAnalyticsEvents('dismiss', this.data, this.notiBlockItem)
    );
  }

  public async onButtonClick(): Promise<void> {
    if (this.notiBlockItem.button_action === 'close') {
      this.isShowNotiBlock = false;
      this.isShowNotiBlockStream$.next();
      this.notiBlockService.updateNotiBlockState(this.isShowNotiBlock);

      await this.notiBlockService.handleDismiss(this.notiBlockItem);
    }
    this.notiBlockService.handleButtonClick(this.notiBlockItem);
    this.analyticsService.eventsTrack(
      this.notiBlockService.getAnalyticsEvents('click', this.data, this.notiBlockItem)
    );
    this.adobeAnalyticsEventsService.sendNotiClick(this.data);
  }

  public get isShowButton(): boolean {
    const isClosableButton =
      this.notiBlockItem.button_action === 'close' &&
      this.notiBlockItem.dismiss === 'none';
    return this.notiBlockItem.button_txt && !isClosableButton;
  }

  public get isShowCloseButton(): boolean {
    return this.notiBlockItem.dismiss !== 'none' && this.notiBlockItem.style === 'noti_strip';
  }

  public showAllClickHandler(url: string): void {
    this.zoneHeaderService.headerClick(url, this.data.is_ebg);
  }
}
