<ng-container *ngIf="!!widgetClass && !!model">
  <div class="{{ widgetClass }}">
    <form class="widget-form"
          ngNoForm
          novalidate
          id="searchForm"
          method="POST"
          [action]="url">
      <div class="{{ widgetClass }}-autocomplete">
        <div *ngIf="widgetClass === 'large-widget'"
             class="{{ widgetClass }}-autocomplete__title">
          <h5>Where are you traveling?</h5>
        </div>
        <label class="widget-label {{ widgetClass }}-autocomplete__label">Destination</label>
        <app-google-places-autocomplete [normalizedDestination]="false"
                                        placeholder="e.g. Miami"
                                        [autocompleteOptions]="autocompleteOptions"
                                        (addressChange)="handleAddressChange($event)">
        </app-google-places-autocomplete>

        <input type="hidden"
               name="place_name"
               id="place_name"
               [value]="placeName" />
        <input type="hidden"
               name="place_lat"
               id="place_lat"
               [value]="placeLat" />
        <input type="hidden"
               name="place_lng"
               id="place_lng"
               [value]="placeLng" />
      </div>

      <div class="hidden-inputs">
        <input type="text"
               name="check_in"
               [value]="checkInDate" />
        <input type="text"
               name="check_out"
               [value]="checkOutDate" />
      </div>

      <div class="{{ widgetClass }}-datepickers">
        <div class="{{ widgetClass }}-datepickers__check-in">
          <label class="widget-label {{ widgetClass }}-datepickers__check-in--label">Check-in</label>
          <input [appAutoTabIndex]="groupTabIndex"
                 placeholder="Check-in"
                 aria-label="Check-in"
                 autocomplete="off"
                 class="widget-input {{ widgetClass }}-datepickers__check-in--input widget-date-input ngb-datepicker"
                 [(ngModel)]="checkIn"
                 [minDate]="minDate"
                 (dateSelect)="onDateSelection($event, 'checkIn')"
                 required="required"
                 ngbDatepicker
                 (click)="datepickerParser.toggleDatepicker($event, dpCheckIn)"
                 #dpCheckIn="ngbDatepicker" />
        </div>
        <div class="{{ widgetClass }}-datepickers__check-out">
          <label class="widget-label {{ widgetClass }}-datepickers__check-out--label">Check-out</label>
          <input [appAutoTabIndex]="groupTabIndex"
                 placeholder="Check-out"
                 aria-label="Check-out"
                 autocomplete="off"
                 class="widget-input {{ widgetClass }}-datepickers__check-in--input widget-date-input ngb-datepicker"
                 [(ngModel)]="checkOut"
                 [minDate]="minDate"
                 (dateSelect)="onDateSelection($event, 'checkOut')"
                 required="required"
                 ngbDatepicker
                 (click)="datepickerParser.toggleDatepicker($event, dpCheckOut)"
                 #dpCheckOut="ngbDatepicker" />
        </div>
      </div>

      <!-- Hidden wrapper to add rooms data to widget form data -->
      <div class="rooms-wrapper"
           *ngFor="let item of roomFormData | keyvalue">
        <input type="text"
               [name]="item.key"
               [value]="item.value" />
      </div>

      <button class="{{ widgetClass }}-rooms-and-guests rooms-and-guests"
              (click)="handleRoomsAndGuests($event)"><span>{{ roomsAndGuestsTitle }}</span>
        <svg width="12"
             height="8"
             [class.rotated-arrow]="showGuestsAndRoomsData"
             [class.standart-arrow]="!showGuestsAndRoomsData"
             viewBox="0 0 12 8"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z"
                fill="#757575" />
        </svg>
      </button>

      <div class="{{ widgetClass }}-action">
        <button [appAutoTabIndex]="groupTabIndex"
                class="btn btn-primary {{ widgetClass }}-action__search"
                type="submit"
                (click)="search()"
                value="Search">
          Search&nbsp;
          <svg *ngIf="!model.isEbg"
               xmlns="http://www.w3.org/2000/svg"
               width="16"
               height="16"
               viewBox="0 0 16 16">
            <path fill="#FFF"
                  fill-rule="nonzero"
                  d="M1 12.75c0 .423.025.84.073 1.25.637-4.344 4.02-4.085 9.312-4.103v2.41L15 7.653 10.385 3v2.411C5.12 5.411 1 6.703 1 12.751z" />
          </svg>
        </button>
      </div>

      <app-rooms-and-guests [@opacityFadeInOut]
                            *ngIf="showGuestsAndRoomsData"
                            (dismissAction)="dismissRoomPopover()"
                            (roomsAndGuests)="handleRoomsAndGuestsData($event)"
                            [roomsData]="roomsData"
                            [widgetId]="model.widgetId"
                            [widgetClass]="widgetClass">
      </app-rooms-and-guests>
    </form>
  </div>
</ng-container>
