import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { PermissionModalService } from '@core/auth/services/permission-modal.service';
import { Router } from '@angular/router';
/* eslint-disable typescriptESlintPlugin/no-explicit-any*/

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective implements OnInit {

  @Input('appHasPermission') requestedPermission: string;
  @Input() allowUndefinedPermission = false;
  @Input() navigateTo: string[];

  @Output() permissionConfirmed: EventEmitter<any> = new EventEmitter();
  @Output() permissionDenied: EventEmitter<boolean> = new EventEmitter();

  hasPermission = false;

  constructor(
    private permissionModalService: PermissionModalService,
    private router: Router
  ) { }

  @HostListener('click', ['$event'])
  onClick(e: any): void {
    if (this.requestedPermission !== null) {
      this.handlePermission(e);
    }
  }

  ngOnInit(): void {
    this.applyPermission();
  }

  applyPermission(): void {

    if (this.allowUndefinedPermission && this.requestedPermission === undefined) {
      this.hasPermission = true;
    } else {
      this.hasPermission = this.permissionModalService.permissionService.hasDefined(this.requestedPermission);
    }
  }

  handlePermission(e: any): void {
    if (this.hasPermission) {
      this.permissionConfirmed.emit(e);
      if (this.navigateTo) {
        void this.router.navigate(this.navigateTo);
      }
    } else {
      this.permissionDenied.emit(true);
      this.showModal();
    }
  }

  showModal(): void {
    this.permissionModalService.showModal();
  }

}
