import { Injectable } from '@angular/core';

@Injectable()
export class PermissionService {

  private store: Array<string> = [];

  constructor() {
    if (localStorage.getItem('permissions')) {
      this.store = localStorage.getItem('permissions').split(',');
    }
  }

  public define(permissions: Array<string>): void {

    if (!Array.isArray(permissions)) {
      throw new Error('permissions parameter is not array.');
    }

    this.clearStore();

    for (const item of permissions) {
      this.add(item);
    }
  }

  public add(permission: string): void {
    if (typeof permission === 'string' && !this.hasDefined(permission.toLocaleLowerCase())) {
      this.store.push(permission.toLocaleLowerCase());
    }
    this.updateLocalStorage();
  }

  public remove(permission: string): void {

    if (typeof permission !== 'string') {
      return;
    }

    const index = this.store.indexOf(permission.toLowerCase());
    if (index < 0) {
      return;
    }

    this.store.splice(index, 1);
    this.updateLocalStorage();
  }

  public hasDefined(permission: string): boolean {

    if (typeof permission !== 'string') {
      return false;
    }

    const index = this.store.indexOf(permission.toLowerCase());
    return index > -1;
  }

  public hasAnyDefined(permissions: Array<string>): boolean {

    if (!Array.isArray(permissions)) {
      throw new Error('permissions parameter is not array.');
    }

    return permissions.some(v => {

      if (typeof v === 'string') {
        return this.store.indexOf(v.toLowerCase()) >= 0;
      }

    });
  }

  public clearStore(): void {
    this.store = [];
    localStorage.removeItem('permissions');
  }

  private updateLocalStorage(): void {
    localStorage.setItem('permissions', this.store.join(','));
  }

}
