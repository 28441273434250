import { Component, Input, OnInit } from '@angular/core';
import snakeCase from 'lodash/snakeCase';

import { Router } from '@angular/router';
import { OfferBadgeType } from '@app/shared/enums/offer-badge-type.enum';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.less', '../../../../styles/partials/badges.less']
})
export class BadgeComponent {
  @Input() public title = '';
  @Input() public iconUrl = '';
  @Input() public badgeIndex = '';
  @Input() public badgeType: OfferBadgeType = OfferBadgeType.None;
  @Input() public withRightCorners = false;

  public offerBadgeType = OfferBadgeType;

  constructor(private router: Router) {}

  public get isTwoLine(): boolean {
    return this.badgeType === OfferBadgeType.TodayDeal && this.title.length > 10;
  }

  public getTitle(): string {
    const shouldPastSpace = this.isTwoLine && this.title.length > 10 && this.title.trim().indexOf(' ') === -1;

    return shouldPastSpace
      ? this.title.substring(0, 10) + ' ' + this.title.substring(10, this.title.length)
      : this.title;
  }

  public isType(value: string): boolean {
    return this.badgeType && this.badgeType === value;
  }

  public get badgeSpecificClass(): Record<string, boolean> {
    const key = snakeCase(this.title.trim().toLowerCase());
    if (!key) {
      return {};
    }
    return {
      [`badge--${key}`]: true
    };
  }
}
