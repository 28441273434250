import { AuthService } from './auth.service';
import { CanActivate, Router } from '@angular/router';
import { ConfigService } from '@app/shared/services/config.service';
import { Injectable } from '@angular/core';
import { UserModel } from './user.model';

@Injectable()
export class SubscriptionsGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private configService: ConfigService
  ) { }

  async canActivate(): Promise<boolean> {
    const accountInfo = await this.authService.getAccountInfo();
    const user = new UserModel(accountInfo);
    const countryLast = user && user.countryLast ? user.countryLast : '';

    if (this.configService.isSubscriptionCenterEnabled(countryLast)) {
      return true;
    }

    void this.router.navigateByUrl('/');
    return false;
  }
}
