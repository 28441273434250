import { Injectable } from '@angular/core';
import {
  LOYALTY_POINTS_MAX_MULTIPLIER,
  LOYALTY_POINTS_PARENT_CAT_CODE
} from '@app/shared/constants/offer.constants';
import { WindowHelper } from 'g3-common-ui';
import { Badge } from '@shared/interfaces/badge.interface';
import { ConfigService } from '@shared/services/config.service';
import { OfferBadgeData } from '@shared/interfaces/offer-badge-data.interface';
import { OfferBadgeSettingsModel } from '@shared/models/offer-badge-settings.model';
import { OfferBadgeType } from '@shared/enums/offer-badge-type.enum';
import { OfferBadgePropertiesModel } from '@shared/models/offer-badge-properties.model';
import { EXTRA_CAT_CODE_TAG_ID_EXPIRING_SOON, EXTRA_CAT_CODE_TAG_ID_NEW, EXTRA_CAT_CODE_TAG_ID_PAYROLL } from '../constants/cat-code-tags.constants';
import { Router } from '@angular/router';

@Injectable()
export class OfferBadgeService {
  private badgeSettings = new OfferBadgeSettingsModel();

  constructor(private readonly configService: ConfigService, private readonly router: Router) {}

  public getDaysLeft(endDate: Date): number {
    if (endDate) {
      return (+(new Date(endDate)) - Date.now()) / 1000 / 60 / 60 / 24;
    }
    return 0;
  }

  public getBadgesForOffer({ offerData, isShowExpired, screenWidth, offerClass, isPaylogixOffer, isEnrolledProduct, userHasPaylogixId, isSearch }: OfferBadgePropertiesModel): Badge[] {
    let darkPills: Badge[] = [];
    const {
      darkPillsCount,
      lightPillsCount,
      loyaltyPillsOnly,
      totalPillsCount,
      reverseSortOnSmallBreakpoint
    } = this.badgeSettings;
    const config = this.configService.getConfig();
    const isClientOfferPillsEnabled = config?.client_offer_pills?.pills_enabled;
    const isClientCategoryPillsEnabled = config?.client_category_pills?.pills_enabled;

    // add sorting rule when reverseSortOnSmallBreakpoint option is set
    if (screenWidth && reverseSortOnSmallBreakpoint) {
      this.badgeSettings.sortingReversed = screenWidth < WindowHelper.SIZE_M;
    }

    if (offerData && offerData.extraStatus && darkPillsCount !== 0 && !loyaltyPillsOnly) {
      const daysLeft = offerData.endDate ? Math.ceil(this.getDaysLeft(offerData.endDate)) : 0;
      const textEnding = daysLeft === 1 ? 'DAY' : 'DAYS';
      const extraStatus = offerData.extraStatus.toLowerCase();

      switch (extraStatus) {
        case 'expired':
          if (isShowExpired) {
            darkPills.push({
              type: OfferBadgeType.Expired,
              title: 'Expired'
            });
          }
          break;
        case 'new':
          if (this.isIncludesSpecificCatCodeTagId(offerData, EXTRA_CAT_CODE_TAG_ID_NEW)) {
            darkPills.push({
              type: OfferBadgeType.New,
              title: 'New'
            });
          }
          break;
        case 'expiring':
          if (this.isIncludesSpecificCatCodeTagId(offerData, EXTRA_CAT_CODE_TAG_ID_EXPIRING_SOON)) {
            darkPills.push({
              type: OfferBadgeType.TimeLimited,
              title: daysLeft === 0 ? 'EXPIRES TODAY' : `${daysLeft} ${textEnding}`
            });
          }
          break;
        case 'limited':
          if (this.isIncludesSpecificCatCodeTagId(offerData, EXTRA_CAT_CODE_TAG_ID_EXPIRING_SOON)) {
            darkPills.push({
              type: OfferBadgeType.TimeLimited,
              title: daysLeft === 0
                ? 'TODAY ONLY'
                : screenWidth < WindowHelper.SIZE_S ? `${daysLeft} days` : `${daysLeft} ${textEnding}`
            });
          }
          break;
      }
    }

    let lightPills = lightPillsCount !== 0
      ? this.addLightPillsToList(offerData, offerClass, screenWidth, isSearch, isPaylogixOffer)
      : [];

    let resultPills: Badge[] = [];

    if (isPaylogixOffer) {
      resultPills = lightPills.concat(darkPills);

      if (userHasPaylogixId) {
        const enrollmentPill = isEnrolledProduct
          ? { type: OfferBadgeType.Enrolled, title: 'Enrolled' }
          : { type: OfferBadgeType.Enrolled, title: 'Not enrolled' };
        resultPills.unshift(enrollmentPill);
      }

      resultPills = this.bringPillToBeginning(resultPills, this.getPayrollDeductionPillTitle(screenWidth, isSearch));

      if (screenWidth < WindowHelper.SIZE_S) {
        resultPills = resultPills.filter(pill => (
          pill.title === this.getPayrollDeductionPillTitle(screenWidth, isSearch) ||
          pill.type === OfferBadgeType.Ads ||
          pill.type === OfferBadgeType.Enrolled
        ));
      }
    } else {
      // if there is a limiting activated, limit number of pills
      if (this.badgeSettings && darkPillsCount) {
        darkPills = this.limitPills(darkPills, darkPillsCount);
      }

      if (this.badgeSettings && lightPillsCount) {
        lightPills = this.limitPills(lightPills, lightPillsCount);
      }

      const showClientOfferPill = isClientOfferPillsEnabled && offerClass && offerClass.toLocaleLowerCase() === 'client';
      if (showClientOfferPill || isClientCategoryPillsEnabled) {
        resultPills = lightPills.concat(darkPills);
      } else {
        resultPills = darkPills.concat(lightPills);
      }

      if (this.badgeSettings && totalPillsCount !== null) {
        resultPills = totalPillsCount
          ? this.limitPills(resultPills, totalPillsCount)
          : [];
      }
    }

    return resultPills;

  }

  public setBadgeSettings(settings: OfferBadgeSettingsModel): void {
    this.badgeSettings = settings;
  }

  private isIncludesSpecificCatCodeTagId(offerData: OfferBadgeData = {}, catCodeTagId: number): boolean {
    return offerData.catCodeTagIds && offerData.catCodeTagIds.length && offerData.catCodeTagIds.includes(catCodeTagId);
  }

  private addLightPillsToList(offerData: OfferBadgeData, offerClass?: string, screenWidth?: number, isSearch?: boolean, isPaylogixOffer?: boolean): Badge[] {
    const { loyaltyPillsOnly } = this.badgeSettings;
    const config = this.configService.getConfig();

    let lightPills = [] as Badge[];

    if (this.isShowClientOfferPill(offerClass, isPaylogixOffer)) {
      const clientPill: Badge  = {
        type: OfferBadgeType.Client,
        title: config?.client_offer_pills?.pill_text,
        iconUrl: config?.client_offer_pills?.pill_icon
      };
      return this.addLightPill(lightPills, clientPill);
    }

    if (this.isShowCategoryPill(offerData, isPaylogixOffer)) {
      const categoryPill: Badge = {
        type: OfferBadgeType.Category,
        title: config?.client_category_pills?.pill_text,
        iconUrl: config.client_category_pills.pill_icon
      };
      return this.addLightPill(lightPills, categoryPill);
    }

    if (!loyaltyPillsOnly) {
      if (offerClass && offerClass.toLocaleLowerCase() === 'exclusive') {
        const exclusivePill = {
          type: OfferBadgeType.Exclusive,
          title: 'Exclusive'
        };
        lightPills = this.addLightPill(lightPills, exclusivePill);
      }

      if (this.isIncludesSpecificCatCodeTagId(offerData, EXTRA_CAT_CODE_TAG_ID_PAYROLL)) {
        const payrollPill = {
          type: OfferBadgeType.Payroll,
          title: this.getPayrollDeductionPillTitle(screenWidth, isSearch)
        };
        lightPills = this.addLightPill(lightPills, payrollPill);
      }
    }

    if (config.loyalty && !isPaylogixOffer) {
      lightPills = this.addLoyaltyPills(lightPills, offerData);
    }

    let result = this.badgeSettings.sortingReversed ? lightPills.reverse() : lightPills;

    if (isSearch && screenWidth < WindowHelper.SIZE_M) {
      const xsPillsOrder = [
        OfferBadgeType.Loyalty,
        OfferBadgeType.Payroll,
        OfferBadgeType.Client,
        OfferBadgeType.Exclusive
      ];

      const orderedPills = xsPillsOrder.map(type => result.find(el => el.type === type)).filter(Boolean);
      result = orderedPills[0] ? [orderedPills[0]] : [];
    }

    return result;
  }

  private isShowClientOfferPill(offerClass: string, isPaylogixOffer: boolean): boolean {
    const config = this.configService.getConfig();
    const isOfferDetailsPage = this.router.url.startsWith('/offers/');

    const isClientOfferPillsEnabled = config?.client_offer_pills?.pills_enabled;
    const clientOfferPillText = config?.client_offer_pills?.pill_text;

    const isClientOfferPillValid = offerClass?.toLocaleLowerCase() === 'client';

    return isClientOfferPillsEnabled &&
      clientOfferPillText &&
      isClientOfferPillValid &&
      !isOfferDetailsPage &&
      !isPaylogixOffer;
  }

  private isShowCategoryPill(offerData: OfferBadgeData, isPaylogixOffer: boolean): boolean {
    const config = this.configService.getConfig();
    const isCategoryPillsEnabled = config?.client_category_pills?.pills_enabled;
    const categoryPillText = config?.client_category_pills?.pill_text;
    const categoryPillTagId = config?.client_category_pills?.pill_category;
    const isCategoryPillValid = categoryPillText && offerData.catCodeTagIds.includes(categoryPillTagId);
    return isCategoryPillsEnabled && isCategoryPillValid && !isPaylogixOffer;
  }

  private addLoyaltyPills(pills: Badge[], offerData: OfferBadgeData): Badge[] {
    const loyaltyPill = this.getLoyaltyPillContent(offerData);
    if (loyaltyPill) {
      return this.addLightPill(pills, loyaltyPill);
    }

    return pills;
  }

  private getLoyaltyPillContent({ catCodeTagIds = [] }: OfferBadgeData): { type: OfferBadgeType; title: string } {
    const pointsMultiplier = catCodeTagIds
      .filter(catCode => catCode - LOYALTY_POINTS_PARENT_CAT_CODE > 0 && catCode - LOYALTY_POINTS_PARENT_CAT_CODE <= LOYALTY_POINTS_MAX_MULTIPLIER)
      .map(catCode => catCode - LOYALTY_POINTS_PARENT_CAT_CODE)
      .sort((a, b) => b - a)
      .shift();

    if (!pointsMultiplier) {
      return;
    }

    return {
      type: OfferBadgeType.Loyalty,
      title: `${pointsMultiplier}x`
    };
  }

  private addLightPill(badgeList: Badge[], pill: Badge): Badge[] {
    if (this.badgeSettings.sortingReversed) {
      badgeList.unshift(pill);
    } else {
      badgeList.push(pill);
    }
    return badgeList;
  }

  private limitPills(pills: Badge[], pillsCount: number): Badge[] {
    return pillsCount
      ? pills.slice(0, pillsCount)
      : pills;
  }

  /**
   * Brings the pill with given title to the beginning of the list
   *
   * @returns - Resulting badges list
   */
  private bringPillToBeginning(pills: Badge[], title: string): Badge[] {
    const pillIndex = pills.findIndex(p => p.title === title);
    if (pillIndex === -1) {
      return pills;
    }
    const rest = [...pills];
    const removedPill = rest.splice(pillIndex, 1);
    return [...removedPill, ...rest];
  }

  private getPayrollDeductionPillTitle(screenWidth: number, isSearch: boolean): string {
    return (!isSearch && screenWidth < WindowHelper.SIZE_S) ? 'Payroll' : 'Payroll Deduction';
  }
}
